import React from 'react';
import { Card, Dialog, DialogTitle, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import './Formdialogue.css';

const useStyles = makeStyles({
  container: {
    overflow: 'hidden',
  },
});

export const FormDialog = ({ show = false, text, title, content = 'content', onClose = () => {}, fullWidth = true, resetPassword }) => {
  const classes = useStyles();
  const handleCancel = () => onClose(false);

  return (
    <Card mb={6}>
      <div>
        <Dialog
          fullWidth={fullWidth}
          open={show}
          className={resetPassword ? 'reset-password-modal' : ''}
          onClose={handleCancel}
          aria-labelledby='form-dialog-title'
          scroll='body'
        >
          {title ? (
            <DialogTitle id='form-dialog-title' style={{ textAlign: 'center' }}>
              {title}
            </DialogTitle>
          ) : null}
          <DialogContent className={classes.container}>
            {text ? <DialogContentText>{text}</DialogContentText> : null}
            {content}
          </DialogContent>
        </Dialog>
      </div>
    </Card>
  );
};
