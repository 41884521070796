export const TOKEN = 'token';
export const ADMIN_CONTACT = '';
export const DEFAULT_PATH = '/user';
export const LOGIN_PATH = '/auth/sign-in';

// Error Types
export const ERROR_PAGE = {
  LICENSE_UNDEFINED: 'LICENSE_UNDEFINED',
  LICENSE_INACTIVE: 'LICENSE_INACTIVE',
  FREQUENCY_ACCESS_DENIED: 'FREQUENCY_ACCESS_DENIED',
  POWER_COST_ACCESS_DENIED: 'POWER_COST_ACCESS_DENIED',
};

export let COST = ['member', 'nonMember', 'everyone', 'patron', 'sponsor', 'individual', 'parent', 'recentGraduate', 'student'];
export const PRICE_TYPES = [
  {
    label: 'Patron',
    value: 'patron',
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
  },
  {
    label: 'Recent Graduate',
    value: 'recentGraduate',
  },

  {
    label: 'Individual',
    value: 'individual',
  },

  {
    label: 'Non Member',
    value: 'inActive',
  },
  {
    label: 'Member',
    value: 'member',
  },
];
export const ROLE = [
  {
    name: 'None',
    id: 'none',
  },
  {
    name: 'Admin',
    id: 'admin',
  },
  {
    name: 'Editor',
    id: 'editor',
  },
  {
    name: 'Subscriber',
    id: 'subscriber',
  },
];

export const USER_ROLE = [
  {
    label: 'None',
    value: ' ',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
  {
    label: 'Subscriber',
    value: 'subscriber',
  },
];

export const TYPE = [
  { id: 'none', name: 'None' },
  { id: 'membership', name: 'Membership' },
  { id: 'event', name: 'Event' },
  { id: 'donation', name: 'Donation' },
];

export const TRANSACTION_TYPES = [
  { value: 'none', label: 'All' },
  { value: 'membership', label: 'Membership' },
  { value: 'event', label: 'Event' },
  { value: 'donation', label: 'Donation' },
];

let temp = [{ id: '', name: 'None' }];
let i = 50.2;

export const FrequencyRange = () => {
  while (i >= 49.78) {
    temp.push({ id: i.toFixed(3), name: i.toFixed(3) });
    i = i - 0.02;
  }

  return temp;
};

export const TITLE = ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Hon.', 'Rev.', 'Sir'];

export const SUFFIX = ['Jr.', 'Sr.', 'II', 'III', 'IV', 'MD.', 'PhD.', 'Esq.'];
export const AFFILIATION = [
  'Harvard College',
  'Graduate School',
  'Business School',
  'Dental School',
  'School Of Design',
  'School Of Education',
  'JFK School Of Government',
  'Law School',
  'Medical School',
  'School Of Public Health',
  'Divinity School',
  'Non-Hardvard Registrant',
];
export const INTERVALS = [
  { label: 'Week', value: 'week' },
  { label: 'Day', value: 'day' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

const TEMP_CLASSES = Array.from({ length: 93 }, (_, i) => {
  return {
    label: (i + 1931).toString(),
    value: i + 1931,
  };
}).reverse();

export const CLASSES = [{ label: 'N/A', value: 0 }, ...TEMP_CLASSES];
