const typography = {
  useNextVariants: true,
  fontFamily: [
     "Poppins", "sans-serif"
  ].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: "2vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "1.25vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1.125vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "1.0625vw",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 14,
  },
  button: {
    textTransform: "none",
  },
};

export default typography;
