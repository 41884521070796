import React, { Fragment, useEffect } from 'react';
import { AuthProvider } from './context/AuthContext';
import { EventProvider } from './context/EventContext';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Routes } from './routes/Routes';
import { ThemeProvider } from 'styled-components';
import maTheme from './theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

import './App.css';
function App() {
  return (
    <Fragment>
      <MuiThemeProvider theme={maTheme[0]}>
        <ThemeProvider theme={maTheme[0]}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AuthProvider>
              <EventProvider>
                <Routes />
              </EventProvider>
            </AuthProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Fragment>
  );
}

export default App;
