function createShadow(px) {
  return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

const shadows = [
  "none",
  createShadow(14),
  createShadow(15),
  createShadow(16),
  createShadow(17),
  createShadow(18),
  createShadow(19),
  createShadow(20),
  createShadow(21),
  createShadow(22),
  createShadow(23),
  createShadow(13),
  createShadow(12),
  createShadow(11),
  createShadow(10),
  createShadow(9),
  createShadow(8),
  createShadow(7),
  createShadow(6),
  createShadow(5),
  createShadow(4),
];

export default shadows;
