import classes from './LocationAutoComplete.module.css';
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const LocationAutoComplete = ({ setLocation }) => {
  const [searchKey, setSearchKey] = useState('');

  const handleChange = (searchKey) => {
    setSearchKey(searchKey);
  };

  const handleSelect = (searchKey) => {
    geocodeByAddress(searchKey)
      .then((results) => {
        setSearchKey(results[0]?.formatted_address);
        getLatLng(results[0]).then((latLng) => setLocation({ ...latLng, name: results[0]?.formatted_address }));
      })
      .catch((error) => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete value={searchKey} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: classes.SearchInput,
            })}
          />
          <div className={classes.DropdownContainer}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '1vw' }
                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '1vw' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  className={classes.Dropdown}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationAutoComplete;
