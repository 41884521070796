import { HttpClient } from "../utils/httpClient";

const PATH = {
  create: "/subscription",
  readAll: "/getAllSubscriptions",
  readAllCoupons:"/subscription/coupon",
  createCoupon:"/subscription/coupon"
};

const Create = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.create, payload)
    .then(callback)
    .catch(error)    
    .finally(next);
    
};
const Update = (id,payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.create}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};
const ReadAll = (limit=1000,start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.readAll}?${ limit ===0 ?null:`limit=${limit}`}`)
    .then(callback)
    .catch(error)
    .finally(next);
};
const readAllCoupons = (start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.readAllCoupons}?limit=${process.env.REACT_APP_LIMIT}`)
    .then(callback)
    .catch(error)
    .finally(next);
};
const GeneateCoupan = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.createCoupon, payload)
    .then(callback)
    .catch(error)    
    .finally(next);
    
};
const DeleteCoupan = (id, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.createCoupon}/${id}`)
    .then(callback)
    .catch(error)    
    .finally(next);
    
};

export const SubscriptionService = {
  ReadAll,
  Create,
  Update,
  readAllCoupons,
  GeneateCoupan,
  DeleteCoupan
};