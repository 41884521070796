import { HttpClient } from '../utils/httpClient';

const PATH = {
  login: '/login',
  get_profile: '/user/profile',
  logout: '/user/logout',
  changePassword: '/user/changePassword',
  create: '/register/admin',
  readAll: '/user',
  update: '/user',
  delete: '/user',
  authenticate: '/whoami',
};

const Login = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.login, payload).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const UpdateById = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Profile = (start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.get_profile).then(callback).catch(error).finally(next);
};

const ReadAllUser = (offset = 0, limit = 10, start, callback, error, next, role = null, search = null) => {
  start();
  return HttpClient.get(
    `${PATH.readAll}?${role != 'none' ? `role=${role}` : null}&${
      offset === 0 && limit === 0 ? null : `offset=${offset}&limit=${limit}&${search ? `search=${search}` : null}`
    }`
  )
    .then(callback)
    .catch(error)
    .finally(next);
};

const Logout = (start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.logout).then(callback).catch(error).finally(next);
};

const Authenticate = (start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.authenticate).then(callback).catch(error).finally(next);
};

const ChangePassword = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.changePassword, payload).then(callback).catch(error).finally(next);
};

const UpdateProfile = (payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.update}`, payload).then(callback).catch(error).finally(next);
};

export const UserService = {
  Login,
  Profile,
  Logout,
  Create,
  ReadAllUser,
  UpdateById,
  Authenticate,
  ChangePassword,
  UpdateProfile,
};
