import * as yup from "yup";

export const AddSubscriptionValidation = yup.object().shape({
  name: yup.string().required("nickname  is required"),

  price: yup.number().required("price is required"),
  interval: yup.string().required("interval is required"),
//   recurring: yup.object().shape({
//     interval: yup.string().required("interval is required"),
//   }),
});
