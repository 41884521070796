import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../components/Loader/Loader';
import { FormDialogMax } from '../../components/FormDialog/FormDialogMax';
import { Grid, makeStyles, Button, Fab as MuiFab, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { PRICE_TYPES, TITLE, SUFFIX, AFFILIATION, CLASSES } from '../../constants';
import { Input, DropdownComponent2, PhoneInput, DropdownComponent, ClassDropdown } from '../../components/Inputs/Input';
import { UserDetailsValidation } from '../../validation/UserValidation';
import { UserService } from '../../services/UserService';
import { TableComponent } from '../../components/Table/TableComponent';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import { AuthContext } from '../../context/AuthContext';
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
});
export const UserDetailsModal = ({ userModal, setuserModal, IntialData }) => {
  const { state } = useContext(AuthContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenwidth] = useState(window.screen.width);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [DropdownVal, setDropdownVal] = useState(null);
  const [suffixVal, setsuffixVal] = useState(null);
  const [affiliationVal, setaffiliationVal] = useState(null);
  const [classesVal, setclassesVal] = useState(null);
  const [roleValue, setroleValue] = useState(null);
  const [initialValues, setinitialValues] = useState({});
  console.log(IntialData);
  useEffect(() => {
    let temp = IntialData;
    for (let [key, value] of Object.entries(IntialData)) {
      if (value === null) {
        temp[key] = '--';
      }
    }
    setinitialValues(temp);
    setDropdownVal(IntialData.title);
    setsuffixVal(IntialData.suffix);
    setclassesVal(String(IntialData.class));
    setaffiliationVal(IntialData.affiliation);
    setroleValue(IntialData.membershipStatus);
  }, [IntialData]);
  useEffect(() => {
    setScreenwidth(window.screen.width);
  }, [window.screen.width]);
  const handleUpdate = (values) => {
    let payload = { ...values };
    if (IntialData.id != undefined) {
      UserService.UpdateById(IntialData.id, payload, startLoading, handleUpdateSuccess, handleUpdateError, stopLoading);
    }
  };
  const handleUpdateSuccess = (res) => {
    console.log(res.data);
    if (res) {
      notifySucess('User details updated!');
      setuserModal(false);
    }
  };
  const handleUpdateError = (err) => {
    console.log(err.response);
  };
  console.log(initialValues);
  const addUserDetailsContent = () => (
    <>
      <Formik initialValues={initialValues} onSubmit={handleUpdate} validationSchema={UserDetailsValidation} enableReinitialize={true}>
        {(props) => (
          <Form>
            <Grid container direction='row' spacing={4}>
              <Grid item xs={6}>
                <div>
                  <DropdownComponent2
                    name='title'
                    label='Title'
                    DropdownVal={DropdownVal}
                    setDropdownVal={setDropdownVal}
                    setFieldValue={props.setFieldValue}
                    setFieldTouched={props.setFieldTouched}
                    getFieldMeta={props.getFieldMeta}
                    values={TITLE}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <DropdownComponent2
                    name='suffix'
                    label='Suffix'
                    DropdownVal={suffixVal}
                    setDropdownVal={setsuffixVal}
                    setFieldValue={props.setFieldValue}
                    setFieldTouched={props.setFieldTouched}
                    getFieldMeta={props.getFieldMeta}
                    values={SUFFIX}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='firstName' label='First Name' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='middleName' label='Middle Name' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='lastName' label='Last Name' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='lastNameAsStudent' label='Last Name As Student' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='email' label='Email' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='addressLine1' label='Address Line1' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <ClassDropdown
                    name='class'
                    label='Class'
                    setDropdownVal={setclassesVal}
                    setFieldValue={props.setFieldValue}
                    setFieldTouched={props.setFieldTouched}
                    getFieldMeta={props.getFieldMeta}
                    values={CLASSES}
                    defaultValue={props.values.class}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <DropdownComponent2
                  name='affiliation'
                  label='Affiliation'
                  setFieldValue={props.setFieldValue}
                  setFieldTouched={props.setFieldTouched}
                  getFieldMeta={props.getFieldMeta}
                  values={AFFILIATION}
                  DropdownVal={affiliationVal}
                  setDropdownVal={setaffiliationVal}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='addressLine1' label='Address Line1' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='city' label='City' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='state' label='State' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='zipcode' label='Zipcode' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='phone' label='Phone' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='occupation' label='Occupation' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='company' label='Company' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='homePhone' label='HomePhone' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='mobilePhone' label='MobilePhone' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='workPhone' label='WorkPhone' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='fax' label='Fax' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='postEmail' label='Post Email' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='gradDegreeAndYear' label='Grad Degree And Year' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='undergradDegreeAndYear' label='Undergrad Degree And Year' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='spouseName' label='Spouse Name' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='spouseOccupation' label='Spouse Occupation' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='hobbies' label='Hobbies' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input name='nameBadge' label='Name Badge' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginBottom: '4vw' }}>
                  <Input name='lastNameAsStudent' label='Last Name as Student' placeholder='test placeholder' disabled />
                </div>
              </Grid>
              <Grid item xs={6}>
                <DropdownComponent2
                  name='membershipStatus'
                  label='Membership Status'
                  setFieldValue={props.setFieldValue}
                  setFieldTouched={props.setFieldTouched}
                  getFieldMeta={props.getFieldMeta}
                  values={PRICE_TYPES}
                  DropdownVal={roleValue}
                  setDropdownVal={setroleValue}
                  disabled
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <>
      <Loader show={loading} />
      <FormDialogMax
        show={userModal}
        onClose={() => setuserModal(false)}
        title={`Details`}
        maxWidth={screenWidth >= 1920 ? 'lg' : 'md'}
        content={addUserDetailsContent()}
        // text={"addFcrBidText"}
      />
    </>
  );
};
