import React, { createContext, useReducer } from "react";
import { initialState } from "./store/EventStore";
import { EventReducer } from "./reducer/EventReducer";

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EventReducer, initialState);
  return (
    <EventContext.Provider value={{ state, dispatch }}>
      {children}
    </EventContext.Provider>
  );
};