import React from 'react';
import { SignIn } from '../pages/Auth/SignIn';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EventIcon from '@material-ui/icons/Event';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SchoolIcon from '@material-ui/icons/School';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import Page404 from '../pages/Auth/Page404';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Users } from '../pages/User/User';
import { Subscription } from '../pages/Subscriptions/Subscription';
import { Events } from '../pages/Events/Events';
import { CreateEvents } from '../pages/Events/CreateEvents';
import { Volunteer } from '../pages/Volunteer/Volunteer';
import { School } from '../pages/School/School';
import { Coupon } from '../pages/Coupon/Coupon';
import { Transaction } from '../pages/Transaction/Transaction';
import { People } from '@material-ui/icons';
import Profile from '../pages/User/Profile';
import ForgetPassword from '../pages/ForgetPassword/ForgetPassword';

const authRoutes = {
  path: '/auth/sign-in',
  component: SignIn,
  children: null,
  isProtected: false,
};

const resetPassRoute = {
  path: '/auth/forgetPassword',
  component: ForgetPassword,
  children: null,
  isProtected: false,
};

const Event = {
  id: 'Events',
  path: '/event',
  icon: <EventIcon />,
  children: [
    {
      path: '/event/createEvent',
      name: 'Create Event',
      isProtected: true,
      component: CreateEvents,
      icon: <EventIcon />,
    },
    {
      path: '/event/events',
      name: 'All Events',
      isProtected: true,
      component: Events,
      icon: <EventIcon />,
    },
  ],
};

const SubcriptionRoutes = {
  id: 'Subscription',
  path: '/subscription',
  icon: <SubscriptionsIcon />,
  containsHome: true,
  component: Subscription,
  isProtected: true,
};

const SchoolRoute = {
  id: 'School',
  path: '/school',
  icon: <SchoolIcon />,
  containsHome: true,
  component: School,
  isProtected: true,
};

const couponRoute = {
  id: 'Coupon',
  path: '/coupon',
  icon: <LocalOfferIcon />,
  containsHome: true,
  component: Coupon,
  isProtected: true,
};

const VolunteerRoutes = {
  id: 'Volunteer',
  path: '/volunteer',
  icon: <LocalLibraryIcon />,
  containsHome: true,
  component: Volunteer,
  isProtected: true,
};

const TransactionRoute = {
  id: 'Transactions',
  path: '/transactions',
  icon: <AccountBalanceWalletIcon />,
  containsHome: true,
  component: Transaction,
  isProtected: true,
};

const userRoutes = {
  id: 'Users',
  path: '/user',
  icon: <PeopleAltIcon />,
  containsHome: true,
  component: Users,
  isProtected: true,
};

const profileRoute = {
  path: '/profile',
  containsHome: true,
  component: Profile,
  isProtected: true,
};

const error = {
  path: '/error/:type',
  component: Page404,
  children: null,
  isProtected: true,
};

export const dashboard = [
  userRoutes,
  Event,
  SubcriptionRoutes,
  SchoolRoute,
  VolunteerRoutes,
  couponRoute,
  TransactionRoute,
  profileRoute,
  error,
];

export const auth = [authRoutes, resetPassRoute];

export default [userRoutes, Event, SubcriptionRoutes, SchoolRoute, VolunteerRoutes, couponRoute, TransactionRoute];
