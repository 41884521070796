import { HttpClient } from '../utils/httpClient';

const PATH = {
  image: '/event/upload',

  create: '/event',
  readAll: '/event',
  eventMapping: '/event',
  guests: '/event',
};

const ImageUpload = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.image, payload).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.create}/${id}`, payload).then(callback).catch(error).finally(next);
};
const ReadAll = (offset = 0, limit = 10, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.readAll}${offset === 0 && limit === 0 ? '' : `?offset=${offset}&limit=${limit}`}`)
    .then(callback)
    .catch(error)
    .finally(next);
};
const EventMapping = (id, offset = 0, limit = 10, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.eventMapping}/${id}/mappings${offset === 0 && limit === 0 ? '' : `?offset=${offset}&limit=${limit}`}`)
    .then(callback)
    .catch(error)
    .finally(next);
};
const EventGuests = (id, offset = 0, limit = 10, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.guests}/${id}/guests${offset === 0 && limit === 0 ? '' : `?offset=${offset}&limit=${limit}`}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const EventService = {
  ImageUpload,
  ReadAll,
  Create,
  Update,
  EventMapping,
  EventGuests,
};
