import { green, grey } from "@material-ui/core/colors";

const greenVariant = {
  name: "Red",
  palette: {
    primary: {
      main: "#800e12",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#800e12",
      contrastText: "#FFF",
    },
  },
 
  header: {
    color: "#800e12",
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: "#800e12",
    },
  },
  sidebar: {
    color: "#FFF",
    background: "#800e12",
    header: {
      color: "#FFF",
      background: "#800e12",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: "#800e12",
      online: {
        background: "#FFF",
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
  body: {
    background: "#F9F9FC",
  },
};

const variants = [greenVariant];

export default variants;
