import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { FormDialogMax } from '../../components/FormDialog/FormDialogMax';
import { EventContext } from '../../context/EventContext';
import { SET_EVENT } from '../../context/constants';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { EventService } from '../../services/EventService';
import Loader from '../../components/Loader/Loader';
import { TableComponent } from '../../components/Table/TableComponent';
import MEDitor from '@uiw/react-md-editor';
import moment from 'moment';
import MapComponent from '../../components/Map/MapComponent';
import DescriptionIcon from '@material-ui/icons/Description';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FaceIcon from '@material-ui/icons/Face';
import { SwitchComponent } from '../../components/SwitchComponent/SwitchComponent';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Input, DropdownComponent, PhoneInput, DropdownComponent2 } from '../../components/Inputs/Input';
import { Field, Form, Formik } from 'formik';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import CancelEvent from './CancelEvent';
import { EventMapModal } from './EventMapModal';
import { EventGuestModal } from './EventGuestModal';
import PostponeEvent from './PostponeEvent';
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  h2: {
    zIndex: 1,
  },
  span: {
    marginLeft: '1vw',
  },
  fab: {
    zIndex: 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    marginTop: '1vw',
    fontSize: '0.95vw',
    width: '20vw !important',
    height: '3vw',
    outline: 'none',
    background: '#ffffff 0% 0% no-repeat padding-box',
    boxShadow: '6px 6px 20px #0000000f',
    border: '1px solid #eeeeee',
    borderRadius: '0.5vw',
    paddingLeft: '3.3vw',
    marginBottom: '2vw',
  },
  Label: {
    fontSize: '1.02vw',
    fontWeight: 500,
    // marginBottom: "2vw",
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
    alignItems: 'center',
  },
});
export const Events = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(EventContext);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState({});
  const [DescriptionModal, setDescriptionModal] = useState(false);
  const [LocationModal, setLocationModal] = useState(false);
  const [CostModal, setCostModal] = useState(false);
  const [dateModal, setdateModal] = useState(false);
  const [OrganiserModal, setOrganiserModal] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0, name: '' });
  const [DataDes, setDataDes] = useState('');
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [CostData, setCostData] = useState({});
  const [dateData, setdateData] = useState({});
  const [OrganiserData, setOrganiserData] = useState({});
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [FILENAME, setFILENAME] = useState('');
  const [exportData, setexportData] = useState([]);
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const [IntialData, setIntialData] = useState({});
  const [eventMapModal, seteventMapModal] = useState(false);
  const [eventGuestModal, seteventGuestModal] = useState(false);

  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);

  const handleFetchAll = () => {
    EventService.ReadAll(0, 0, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading);
  };

  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  const handleAllFetchSuccess = (res) => {
    let data = res.data.data.data;
    const processedData = data.map((eachData) => {
      return {
        ...eachData,
        cost: JSON.stringify(eachData.cost),
        isPostponed: Boolean(eachData.isPostponed).toString(),
        isCancelled: Boolean(eachData.isCancelled).toString(),
        organizer: JSON.stringify(eachData.organizer),
        location: JSON.stringify(eachData.location),
        eventInfo: JSON.stringify(eachData.eventInfo),
      };
    });
    console.log(processedData);
    if (data.length > 0) {
      setKEYS(Object.keys(processedData[0]));
      setHEAD(Object.keys(processedData[0]));
    }
    setexportData(processedData);
  };

  const handleAllFetchError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    dispatch({
      type: SET_EVENT,
      payload: {},
    });
    EventService.ReadAll(offset, limit, startLoading, handleDataSuccess, handleDataError, stopLoading);
  }, [offset]);

  let _exporter;

  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };

  const mdReader = () => <MEDitor.Markdown source={DataDes} />;
  const mapReader = () => (
    <>
      <div className={classes.Label}>{'Loaction Name'} </div>
      <div>
        <input
          className={classes.input}
          name='name'
          label='Loaction Name'
          value={location.name}
          onChange={(e) => {
            setLocation({ ...location, name: e.target.value });
          }}
          disabled
        />
      </div>
      <MapComponent defaultLocation={location} setLocation={setLocation} setShowModal={setDescriptionModal} location={location} />
    </>
  );
  const costReader = () => (
    <TableComponent
      title=''
      inline={null}
      description=''
      keys={CostData.keys != undefined ? CostData.keys : []}
      head={CostData.head != undefined ? CostData.head : []}
      rows={CostData.rows != undefined ? CostData.rows : []}
    />
  );
  const datesReader = () => (
    <TableComponent
      title=''
      inline={null}
      description=''
      keys={dateData.keys != undefined ? dateData.keys : []}
      head={dateData.head != undefined ? dateData.head : []}
      rows={dateData.rows != undefined ? dateData.rows : []}
    />
  );
  const organiserReader = () => (
    <Formik
      initialValues={{
        name: OrganiserData.name != null ? OrganiserData.name : '--',
        enabled: OrganiserData.enabled != null ? OrganiserData.enabled : false,
        phone: OrganiserData.phone != null ? OrganiserData.phone : '--',
        email: OrganiserData.email != null ? OrganiserData.email : '--',
      }}
      onSubmit={() => {}}
    >
      {(props) => (
        <Form>
          <Grid container direction='column' spacing={4}>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='name' label='Name' placeholder='Jessica' disabled />
              </div>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <div>
                <PhoneInput name='phone' label='Phone' disabled />
              </div>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' disabled />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <SwitchComponent checked={OrganiserData.enabled === 1 ? true : false} />
            </Grid>
            <Grid container spacing={3} className={classes.dialogButtons}></Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
  const handleDes = (data) => {
    setDataDes(data);
    setDescriptionModal(true);
  };
  const handleLocation = (data) => {
    if (data != null) {
      setLocation({ lat: data.lat, lng: data.lng, name: data.name });
      setLocationModal(true);
    }
  };

  const handleDates = (item) => {
    let head = ['Start Date', 'End Date', 'RSVP Date'];
    let keys = ['startDate', 'endDate', 'rsvpDate'];
    let rowdata = [
      {
        startDate:
          item.startDate === null
            ? '--'
            : `${new Date(item.startDate).toLocaleDateString()} ${new Date(item.startDate).toLocaleTimeString()}`,
        endDate:
          item.endDate === null ? '--' : `${new Date(item.endDate).toLocaleDateString()} ${new Date(item.endDate).toLocaleTimeString()}`,
        rsvpDate:
          item.rsvpDate === null ? '--' : `${new Date(item.rsvpDate).toLocaleDateString()} ${new Date(item.rsvpDate).toLocaleTimeString()}`,
      },
    ];
    let temp = {};
    temp.head = head;
    temp.keys = keys;
    temp.rows = rowdata;

    setdateData(temp);
    setdateModal(true);
  };
  const handleCost = (item) => {
    let head = ['Patron', 'Sponsor', 'Non Member', 'Individual', 'Recent Graduate'];
    let keys = ['patron', 'sponsor', 'inActive', 'individual', 'recentGraduate'];
    let rowdata = [
      {
        patron: item.patron === null ? 'Not Allowed' : item.patron,
        sponsor: item.sponsor === null ? 'Not Allowed' : item.sponsor,

        inActive: item.inActive === null ? 'Not Allowed' : item.inActive,
        individual: item.individual === null ? 'Not Allowed' : item.individual,
        recentGraduate: item.recentGraduate === null ? 'Not Allowed' : item.recentGraduate,
      },
    ];
    let temp = {};
    temp.head = head;
    temp.keys = keys;
    temp.rows = rowdata;

    setCostData(temp);
    setCostModal(true);
  };

  const handleOrganiser = (item) => {
    setOrganiserData(item);
    setOrganiserModal(true);
  };
  const handleAllActions = (item) => (
    <>
      <span
        variant='contained'
        className={classes.span}
        color='primary'
        onClick={() => {
          handleDes(item.description);
        }}
      >
        <DescriptionIcon color='primary' />
      </span>

      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          handleLocation(item.location);
        }}
      >
        <LocationOnIcon color='primary' />
      </span>

      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          handleOrganiser(item.organizer);
        }}
      >
        <ApartmentIcon color='primary' />
      </span>

      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          handleCost(item.cost);
        }}
      >
        <AttachMoneyIcon color='primary' />
      </span>

      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          handleDates(item);
        }}
      >
        <EventIcon color='primary' />
      </span>
      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          seteventMapModal(true);
          setIntialData(item);
        }}
      >
        <EventAvailableIcon color='primary' />
      </span>
      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          seteventGuestModal(true);
          setIntialData(item);
        }}
      >
        <FaceIcon color='primary' />
      </span>
      <span
        className={classes.span}
        variant='contained'
        color='primary'
        onClick={() => {
          dispatch({
            type: SET_EVENT,
            payload: item,
          });
          console.log(history);
          history.push('/event/createEvent');
        }}
      >
        <EditIcon color='primary' />
      </span>
    </>
  );
  const handleDataSuccess = (res) => {
    let data = res.data.data.data;

    if (data.length) {
      let head = ['S.No', 'Title', 'Total Tickets', 'Rsvp Count', 'Cancel Event', 'Postpone Event', 'Actions'];
      let paginate = res.data.data.pagination.totalCount;

      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(paginate / 10),
      }));

      let rowdata = data.map((item, index) => ({
        sno: (pagination.curPage - 1) * 10 + index + 1,
        title: item.title,
        totalTickets: item.totalTickets,
        rsvpedCount: item.rsvpedCount,
        isCancelled: <CancelEvent item={item} startLoading={startLoading} stopLoading={stopLoading} />,
        isPostponed: <PostponeEvent item={item} startLoading={startLoading} stopLoading={stopLoading} />,
        action: handleAllActions(item),
      }));
      let keys = ['sno', 'title', 'totalTickets', 'rsvpedCount', 'isCancelled', 'isPostponed', 'action'];
      let temp = {};
      temp.head = head;
      temp.keys = keys;
      temp.rows = rowdata;

      setTable(temp);
    }
  };
  const handleDataError = (err) => {
    console.log(err);
  };
  return (
    <>
      <Loader show={loading} />
      <FormDialog onClose={() => setDescriptionModal(false)} show={DescriptionModal} title={`Description`} text={''} content={mdReader()} />
      <FormDialog onClose={() => setLocationModal(false)} show={LocationModal} title={`Location`} text={''} content={mapReader()} />
      <FormDialogMax onClose={() => setCostModal(false)} show={CostModal} title={`Cost`} text={''} content={costReader()} maxWidth={'md'} />
      <FormDialog
        onClose={() => setOrganiserModal(false)}
        show={OrganiserModal}
        title={`Organizer`}
        text={''}
        content={organiserReader()}
        maxWidth={'sm'}
      />
      <FormDialogMax
        onClose={() => setdateModal(false)}
        show={dateModal}
        title={`Dates`}
        text={''}
        content={datesReader()}
        maxWidth={'md'}
      />
      <EventMapModal IntialData={IntialData} eventMapModal={eventMapModal} seteventMapModal={seteventMapModal} />
      <EventGuestModal IntialData={IntialData} eventGuestModal={eventGuestModal} seteventGuestModal={seteventGuestModal} />
      <Grid container direction='column'>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
            <div>
              <Typography variant='h1' className={classes.h2}>
                <div style={{ marginRight: '4.5vw' }}>Events</div>
              </Typography>
            </div>
            <div>
              <Fab className={classes.fab} mx={2} size='small' color='secondary' aria-label='Add'>
                <AssignmentReturnedIcon onClick={handleFetchAll} />
              </Fab>
              {exportData != 0 && (
                <ExcelExport
                  data={exportData}
                  fileName={'Events.xlsx'}
                  ref={(exporter) => {
                    _exporter = exporter;
                  }}
                >
                  {HEAD.map((item, i) => (
                    <ExcelExportColumn field={item} title={KEYS[i]} width={100} />
                  ))}
                </ExcelExport>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TableComponent
            pagination={pagination}
            setPagination={setPagination}
            title=''
            inline={null}
            description=''
            keys={table.keys != undefined ? table.keys : []}
            head={table.head != undefined ? table.head : []}
            rows={table.rows != undefined ? table.rows : []}
          />
        </Grid>
      </Grid>
    </>
  );
};
