const breakpoints = {
  values: {
    xs: 0,
    sm: 500,
    md: 500,
    lg: 1600,
    xl: 1600,
  },
};

export default breakpoints;
