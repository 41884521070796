import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
const useStyles = makeStyles({
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  input: {
    marginTop: '1vw',
    fontSize: '0.95vw',
    width: '100%',
    height: '3vw',
    outline: 'none',
    background: '#ffffff 0% 0% no-repeat padding-box',
    boxShadow: '6px 6px 20px #0000000f',
    border: '1px solid #eeeeee',
    borderLadius: '0.5vw',
    paddingLeft: '3.3vw',
    marginBottom: '2vw',
  },
});
export const Copy2Clip = ({ data = '' }) => {
  const classes = useStyles();
  let [Value, setValue] = useState('');
  let [copied, setCopied] = useState(false);
  return (
    <>
      <div>
        <input className={classes.input} name='name' label=' Name' value={encodeURI(data)} />

        <CopyToClipboard text={encodeURI(data)} onCopy={() => setCopied(true)}>
          <Button className={classes.button} fullWidth mb={2} variant='contained' color='primary' disabled={false}>
            Copy Image Url
          </Button>
        </CopyToClipboard>

        {copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
      </div>
    </>
  );
};
