import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TableContainer,
  Grid,
} from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
const useStyles = makeStyles({
  inline: {
    fontWeight: 'normal',
    fontSize: '0.9rem',
    background: '#e8e4e4',
    borderRadius: '50px',
    padding: '4px 8px',
  },
  refresh: {
    verticalAlign: 'middle',
    margin: '0 1rem',
    cursor: 'pointer',
  },
  container: {
    maxHeight: '100%',
  },
  root: {
    width: '97%',
  },
  pagination: {
    marginLeft: 'auto',
    float: 'right',
    marginTop: '1%',
  },
});

export const TableComponent = ({
  keys = [],
  head = [],
  rows = [],
  noDataMessage = 'No records to display',
  pagination = { totalPages: 0, curPage: 0 },
  setPagination = null,
}) => {
  const classes = useStyles();
  return (
    <Paper style={{ margin: '1.4vw 1.3vw' }} className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {head.map((item) => (
                <TableCell align={'center'}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {keys.map((key) => (
                  <TableCell component='th' scope='row' align={'center'}>
                    {row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && <Typography style={{ margin: '2vw', textAlign: 'center' }}>{noDataMessage}</Typography>}
      </TableContainer>

      {pagination != null && pagination.totalPages > 1 ? (
        <Grid item xs={12} className={classes.pagination + ' paginate'}>
          <Pagination
            count={pagination.totalPages}
            page={pagination.curPage}
            variant='outlined'
            color='primary'
            onChange={(e, value) =>
              setPagination((prev) => ({
                ...prev,
                curPage: value,
              }))
            }
          />
        </Grid>
      ) : null}
    </Paper>
  );
};
