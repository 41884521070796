import React, { useState } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyle = makeStyles({
  arrow: {
    margin: "auto 10px",
    cursor: "pointer",
  },
});

export const DateSelectorWithoutArrow = ({
  label = "Date",
  value = new Date(),
  startDate = new Date(),
  endDate = new Date(),
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyle();
  const oneDay = 1000 * 3600 * 24;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2}>
        <Grid item>
          <DatePicker
          style={{width:"31.8vw !important"}}
            views={["month", "date"]}
            label={label}
            // minDate={startDate}
            //maxDate={endDate}
            value={value}
            onChange={onChange}
            {...rest}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
