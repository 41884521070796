import * as yup from 'yup';
const Error = {
  number: 'Field must be a number',
};

const validate = (field) => {
  return yup
    .number(Error.number)
    .nullable()
    .when(field, {
      is: true,
      then: yup.number().min(0).max(0).required('This is a required field'),
    });
};

export const EventFormValidation = yup.object().shape({
  title: yup.string().required('Title is required'),
  image: yup.string().url().required('Image is required , please upload and paste the url'),
  homepageImage: yup.string().url().required('Homepage Image is required , please upload and paste the url'),
  description: yup.string().required('Description is required'),
  totalTickets: yup.number().required('TotalTickets is required'),
  eventType: yup.string(),
  url: yup.string().when('eventType', {
    is: (value) => value === 'thirdParty',
    then: yup.string().url().required(),
    otherwise: yup.string().nullable(),
  }),
  organizer: yup.object({
    name: yup.string().when('enabled', {
      is: 1,
      then: yup.string().required('Required'),
      otherwise: yup.string().nullable(),
    }),
    email: yup
      .string()
      .email()
      .when('enabled', {
        is: 1,
        then: yup.string().required('Required'),
        otherwise: yup.string().nullable(),
      }),
    phone: yup.string(),
    enabled: yup.number().default(1),
  }),
});

export const EventFormValidationUpdate = yup.object().shape({
  title: yup.string().required('title is required'),
  image: yup.string().required('image is required , upload an Image and paste the url'),
  description: yup.string().required('description is required'),
  totalTickets: yup.number().required('totalTickets is required'),
  eventInfo: yup.object({
    url: yup.string(),
  }),
  cost: yup.object({
    patron: yup.number().nullable(),
    recentGraduate: yup.number().nullable(),
    sponsor: yup.number().nullable(),
    student: yup.number().nullable(),
    individual: yup.number().nullable(),
    inActive: yup.number().nullable(),
    parent: yup.number().nullable(),
  }),
  organizer: yup.object({
    name: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),
  }),
});
