import React, { memo } from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: ${"100%"};

`;

const Auth = memo(({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
});

export default Auth;
