import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { SwitchComponent } from '../../components/SwitchComponent/SwitchComponent';
import { EventService } from '../../services/EventService';

function PostponeEvent({ item, startLoading, stopLoading }) {
  const [isPostponed, setisPostponed] = useState(item.isPostponed);
  const [open, setOpen] = useState({ show: false, state: false });

  useEffect(() => {
    setisPostponed(item.isPostponed);
  }, [item.isPostponed]);

  const handleCancel = (e) => {
    setOpen({
      show: true,
      state: e,
    });
  };

  const handleEventSucces = (res) => {
    console.log(res);
    setOpen(false);
  };

  const handleEventError = (err) => {
    console.log(err);
  };

  const confirmDialog = () => {
    return (
      <div>
        <div>Are you sure want to postpone the event?</div>
        <div style={{ display: 'flex', marginTop: '1vw', gridColumnGap: '1vw', justifyContent: 'flex-end' }}>
          <div>
            <Button variant='contained' color='primary' onClick={confirmCancel}>
              Yes
            </Button>
          </div>
          <div>
            <Button variant='contained' color='primary' onClick={() => setOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const confirmCancel = () => {
    setisPostponed(open.state);
    let payload = { isPostponed: open.state };
    EventService.Update(item.id, payload, startLoading, handleEventSucces, handleEventError, stopLoading);
  };

  return (
    <>
      <SwitchComponent onClick={(e) => (!isPostponed ? handleCancel(e.target.checked) : null)} checked={isPostponed} />
      <FormDialog
        show={open.show}
        onClose={() => setOpen({ ...open, show: false })}
        title='Confirm Postpone Event'
        content={confirmDialog()}
        text=''
      />
    </>
  );
}

export default PostponeEvent;
