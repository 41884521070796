import React, { useState } from 'react';
import { Input, Password } from '../../components/Inputs/Input';
import classes from './ForgetPassword.module.css';
import { Form, Formik } from 'formik';
import { ForgetPasswordFormInitialValues, ForgetPasswordFormValidation } from '../../validation/ForgetPasswordFormValidation';
import { NavLink, useHistory } from 'react-router-dom';
import { generateOtp, resetPassword, verifyOTP } from '../../utils/FormSubmitHandler';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';

const ForgetPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [token, setToken] = useState(null);

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    if (!otpSent)
      generateOtp(values)
        .then(({ data }) => {
          setLoading(false);
          toast.success('OTP Sent succesfully');
          setOtpSent(true);
        })
        .catch((err) => {
          setLoading(false);
          toast.error('Error in sending OTP!');
          return;
        });
    else if (!otpVerified) {
      verifyOTP(values)
        .then(({ data }) => {
          setLoading(false);
          setToken(data.data.token);
          toast.success('OTP Verified');
          setOtpVerified(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error('Please provide valid OTP!');
          return;
        });
    } else {
      let processedValues = { ...values };
      delete processedValues.confirmPassword;

      if (processedValues.newPassword && processedValues.newPassword.length >= 6)
        resetPassword(token, values)
          .then(({ data }) => {
            setLoading(false);
            toast.success('Password changed successfully!');
            history.push('/auth/sign-in');
          })
          .catch((err) => {
            setLoading(false);
            toast.error('Something went wrong!');
            console.log(err);

            return;
          });
      else if (processedValues.newPassword) {
        setLoading(false);
        toast.error('New password should be atleast 6 characters');
      } else {
        toast.error('Please provide new password');
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.Login}>
      <Loader show={loading} />
      <div className={classes.LoginOverlay}></div>
      <div className={classes.LoginContainer}>
        <div className={classes.Title}>
          <p>
            Forgot <br /> Password
          </p>
        </div>
        <div className={classes.Fields}>
          <div className={classes.FormContainer}>
            <Formik initialValues={ForgetPasswordFormInitialValues} validationSchema={ForgetPasswordFormValidation} onSubmit={handleSubmit}>
              <Form>
                <div>
                  <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' disabled={otpSent || otpVerified} required />
                </div>
                {!otpVerified ? (
                  <>
                    {otpSent && (
                      <div>
                        <Input name='otp' label='OTP' placeholder='98032' required />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <Password name={!otpVerified ? 'password' : 'newPassword'} label='New Password' required />
                    </div>
                    <div>
                      <Password name='confirmPassword' label='ConfirmPassword' required />
                    </div>
                  </>
                )}
                <div className={classes.ForgetPassword}>
                  <button type='submit' className=' btn-primary btn-brown'>
                    {!otpSent ? 'Submit' : !otpVerified ? 'Verify OTP' : 'Reset Password'}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
