import { SET_EVENT, REMOVE_EVENT} from "../constants";

export const EventReducer = (state, action) => {
  switch (action.type) {
    case SET_EVENT:
      return {
        ...state,
        event: action.payload,
       
      };
    case REMOVE_EVENT:
      return {
        ...state,
        event: {},
     
      };
    default:
      return state;
  }
};
