import React, { useState } from 'react';
import ImageUploader from 'react-images-upload';
import { EventService } from '../../services/EventService';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import BackupIcon from '@material-ui/icons/Backup';
import Loader from '../../components/Loader/Loader';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { Copy2Clip } from '../../components/CopyToClipboardComponent/Copy2Clip';
import './event.css';
import CreateEventForm from './CreateEventForm1';
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  h2: {
    zIndex: 1,
    position: 'absolute',
    right: '33.297vw',
    margin: '0.791vw 4.006vw 0 0',
  },
  pagination: {
    marginLeft: 'auto',
  },
  fab: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    margin: '0.791vw 4.006vw 0 0',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  picture: {
    width: '43.075vw',
    height: 'auto',
  },
});
export const CreateEvents = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [showUserForm, setshowUserForm] = useState(false);
  let [pictures, setPictures] = useState(null);
  const onDrop = (picture) => {
    const formData = new FormData();
    if (picture[0]) {
      formData.append('avatar', picture[0], new Date().toISOString() + picture[0].name);
      EventService.ImageUpload(formData, startLoading, handleUploadSuccess, handleUploadError, stopLoading);
    }
  };

  const addUserContent = () => (
    <>
      {pictures == null ? (
        <ImageUploader
          withIcon={true}
          buttonText='Choose image'
          onChange={onDrop}
          imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
          maxFileSize={5242880}
        />
      ) : null}
      <Grid conatiner direction='column' spacing={3}>
        <Grid item xs={12}>
          {pictures != null ? <img src={pictures} className={classes.picture} alt='pic' /> : null}
        </Grid>
        <Grid item xs={12}>
          {pictures != null ? <Copy2Clip data={pictures} /> : null}
        </Grid>
      </Grid>
    </>
  );

  const handleUploadSuccess = (res) => {
    console.log(res);
    setPictures(res.data.data);
  };

  const handleUploadError = (err) => {
    console.log(err);
  };

  const handleClickUpload = () => {
    setshowUserForm(true);
    setPictures(null);
  };

  return (
    <>
      <Loader show={loading} />
      <FormDialog
        show={showUserForm}
        onClose={() => {
          setshowUserForm(false);
        }}
        title={`${showUserForm !== true ? 'Edit' : 'Create'} Event`}
        content={addUserContent()}
        text={showUserForm !== true ? '' : ''}
      />
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>TEST</h4>
            </CardHeader>
            <CardContent className='table-scroll user-table'>
              <CreateEventForm handleClickUpload={handleClickUpload} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
