import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Visibility';
import { Field, Form, Formik } from 'formik';
import { Input, DropdownComponent, PhoneInput, DropdownComponent2 } from '../../components/Inputs/Input';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { spacing } from '@material-ui/system';
import './user.css';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AuthContext } from '../../context/AuthContext';
import { TableComponent } from '../../components/Table/TableComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import Loader from '../../components/Loader/Loader';
import { AddSubscriptionValidation } from '../../validation/SubscriptionValidation';
import { SubscriptionService } from '../../services/SubscriptionService';
import { AddSubscriptionModal } from './AddSubscriptionModal';
import { camelToSentence, Capitalize } from '../../utils/StringHelper';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DeviceHub as DeviceHubIcon,
  DevicesOther as DeviceOtherIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { ROLE, INTERVALS } from '../../constants';
const options = ROLE;

const defaultOption = options[0];
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
    alignItems: 'center',
  },
});
export const Subscription = () => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);
  const [showSubscriptionForm, setshowSubscriptionForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [role, setRole] = useState('editor');
  const [filter, setFilter] = useState('none');
  const [table, setTable] = useState({});
  const [userModal, setuserModal] = useState(false);
  const [IntialData, setIntialData] = useState({});
  const [AllData, setAllData] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  useEffect(() => {
    if (userModal === false && showSubscriptionForm === false) {
      fetchUsers();
    }
  }, [offset, limit, userModal, filter, showSubscriptionForm]);
  // useEffect(() => {
  //   let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
  //   setoffset(skip);
  // }, [pagination]);
  const fetchUsers = () => {
    SubscriptionService.ReadAll(process.env.REACT_APP_LIMIT, startLoading, handleFetchSuccess, handleFetchError, stopLoading, filter);
  };
  useEffect(() => {
    let rowData =
      AllData &&
      AllData.map(
        (item, index) =>
          item?.active && {
            ...item,
            sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
            nickname: item != undefined && item.nickname != undefined ? camelToSentence(item.nickname) : '--',
            interval: item.recurring.interval,
            unit_amount: (
              <>
                <span>&#36;</span> {`${item.unit_amount / 100}`}
              </>
            ),
          }
      ).filter(Boolean);
    let keys = ['sno', 'nickname', 'interval', 'unit_amount'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  }, [offset, limit, AllData]);

  const handleFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data;
    console.log(data);
    const totalPage = data.length;
    console.log(totalPage);
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(totalPage / 10),
    }));

    if (data.length > 10) {
      let rawData = [];
      if (offset === 0) {
        for (let i = offset; i < limit; i++) {
          rawData.push(data[i]);
        }
        setAllData(rawData);
      } else {
        for (let i = offset; i < offset + 10 && i <= data.length - 1; i++) {
          rawData.push(data[i]);
        }
        setAllData(rawData);
      }
    } else {
      setAllData(data);
    }
  };
  const handleFetchError = (res) => {
    console.log(res);
  };

  const handleSubmit = (values) => {
    console.log(values);
    let payload = {
      ...values,
      price: parseInt(values.price) * 100,
    };
    SubscriptionService.Create(payload, startLoading, handleCreatesuccess, handleCreateError, stopLoading);
  };
  const handleCreatesuccess = (res) => {
    if (res) {
      notifySucess('Subscription Created!');
      setshowSubscriptionForm(false);
    }
  };
  const handleCreateError = (err) => {
    notifyWarning('something went wrong!');
  };
  const addUserContent = (edit = false) => (
    <Formik
      initialValues={{
        name: '',
        price: 0,
        interval: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={AddSubscriptionValidation}
    >
      {(props) => (
        <Form>
          <Grid container direction='column' spacing={4}>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='name' label='Name' placeholder='Jessica' />
              </div>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <DropdownComponent
                name='interval'
                label='Interval'
                setFieldValue={props.setFieldValue}
                setFieldTouched={props.setFieldTouched}
                getFieldMeta={props.getFieldMeta}
                values={INTERVALS}
              />
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='price' label='Price' placeholder='price is here in cent' />
              </div>
            </Grid>
            <Grid container spacing={3} className={classes.dialogButtons}>
              <Grid item xs={4}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setshowSubscriptionForm(false);
                  }}
                  fullWidth
                  mb={2}
                  variant='contained'
                  color='primary'
                  disabled={false}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className={classes.button} fullWidth variant='contained' color='primary' mb={2} type='submit'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <Loader show={loading} />
      <AddSubscriptionModal IntialData={IntialData} userModal={userModal} setuserModal={setuserModal} />
      <FormDialog
        show={showSubscriptionForm}
        onClose={() => setshowSubscriptionForm(false)}
        title={`${showSubscriptionForm !== true ? 'Edit' : 'Create'} Subscription`}
        content={addUserContent()}
        text={showSubscriptionForm !== true ? '' : ''}
      />
      <Grid container direction='column'>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
            <div>
              <Typography variant='h1' className={classes.h2}>
                <div style={{ marginRight: '4.5vw' }}>Subscriptions</div>
              </Typography>
            </div>
            <div>
              {state.user.role !== 'editor' ? (
                <Fab className={classes.fab} mx={2} size='small' color='secondary' aria-label='Add'>
                  <AddIcon
                    onClick={() => {
                      setshowSubscriptionForm(true);
                    }}
                  />
                </Fab>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TableComponent
            pagination={pagination}
            setPagination={setPagination}
            title=''
            inline={''}
            description=''
            keys={table.keys != undefined ? table.keys : []}
            head={['S.No', 'Name', 'Interval', 'Price']}
            rows={table.rows != undefined ? table.rows : []}
          />
        </Grid>
      </Grid>
    </>
  );
};
