import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import { FormDialogMax } from '../../components/FormDialog/FormDialogMax';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import styled from 'styled-components';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { EventService } from '../../services/EventService';
import { TableComponent } from '../../components/Table/TableComponent';
import { ROLE, TYPE } from '../../constants';
import { spacing } from '@material-ui/system';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { camelToSentence } from '../../utils/StringHelper';
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  span: {
    marginLeft: '2vw',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  h2: {
    zIndex: 1,
    position: 'absolute',
    right: '33.297vw',
    margin: '0.791vw 4.006vw 0 0',
  },
  filter: {
    zIndex: 1,
    position: 'absolute',
    right: '68.297vw',
    margin: '4.91vw 2.006vw 0 0',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  fab2: {
    zIndex: 1,
    position: 'absolute',
    right: '5%',
    margin: '0.291vw 4.006vw 0 0',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
});
export const EventGuestModal = ({ IntialData, eventGuestModal, seteventGuestModal }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [screenWidth, setScreenwidth] = useState(window.screen.width);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [filter, setFilter] = useState('none');
  const [table, setTable] = useState({});
  const [exportData, setexportData] = useState([]);
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [guestList, setGuestList] = useState(false);
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);
  let _exporter;
  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };
  useEffect(() => {
    setScreenwidth(window.screen.width);
  }, [window.screen.width]);

  const handleFetchAll = () => {
    EventService.EventGuests(IntialData.id, 0, 0, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading);
  };
  const handleAllFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;
    if (data.length > 0) {
      let tempGuest = [];
      data.map((item, index) => {
        if (item.guestList.length > 0) {
          item.guestList.map((guest, j) => {
            let object = {
              // Sno:pagination.curPage === 1
              // ? j + 1
              // : (pagination.curPage - 1) * 10 + (j + 1),
              guest: guest.name,
              guestEmail: guest.email,
              name: `${item.User.firstName}${item.User.middleName != null ? item.User.middleName : ''}${item.User.lastName}`,
              email: item.User.email,
              customerId: item.User.customerId,
              tickets: JSON.stringify(item.tickets),
              membershipStatus: item.User.membershipStatus,
            };

            tempGuest.push(object);
          });
        }
      });

      let keys = ['guest', 'guestEmail', 'name', 'email', 'customerId', 'tickets', 'membershipStatus'];
      setKEYS(keys);
      setHEAD(['Guest', 'Guest Email', 'User Purchase', 'Email', 'Customer Id', 'Tickets', 'Membership Status of Purchaser']);
      setexportData(tempGuest);
    }
  };
  const handleAllFetchError = (err) => {
    console.log(err);
  };
  useEffect(() => {
    if (IntialData.id != undefined) {
      EventService.EventGuests(
        IntialData.id,
        offset,
        limit,
        startLoading,
        handleFetchEventMappSuccess,
        handleFetchEventMappError,
        stopLoading
      );
    }
  }, [IntialData, offset]);
  const handleFetchEventMappSuccess = (res) => {
    let paginate = res.data.data.pagination.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));
    let data = res.data.data.data;
    console.log(data);
    let tempGuest = [];
    data.map((item, index) => {
      let object = {
        Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
        name: `${item.User.firstName} ${item.User.middleName != null ? item.User.middleName : ' '}${item.User.lastName}`,
        email: item.User.email,
        customerId: item.User.customerId,
        tickets: JSON.stringify(item.tickets),
        guests: (
          <div style={{ textDecoration: 'underline', color: 'darkred' }} onClick={() => handleSetGuests(item.guestList)}>
            Show Guests
          </div>
        ),
        membershipStatus: item.User.membershipStatus,
      };

      tempGuest.push(object);
    });

    let keys = ['Sno', 'name', 'email', 'customerId', 'tickets', 'guests', 'membershipStatus'];
    let temp = {};
    temp.keys = keys;
    temp.rows = tempGuest;
    setTable(temp);
  };
  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  const handleFetchEventMappError = (err) => {
    console.log(err);
  };

  const eventMapDetails = () => (
    <>
      <Grid conatiner direction='row' justify='space-around' alignItems='center' spacing={3}>
        <Grid item={6} style={{ textAlign: 'right' }}>
          <Fab className={classes.fab} mx={2} size='medium' color='secondary' aria-label='Add'>
            <AssignmentReturnedIcon onClick={handleFetchAll} />
          </Fab>
        </Grid>
      </Grid>
      <Card>
        <TableComponent
          pagination={pagination}
          setPagination={setPagination}
          title='Events Guests Table'
          inline={null}
          description=''
          keys={table.keys != undefined ? table.keys : []}
          head={['Sno', 'Purchased User', 'Email', 'Customer Id', 'Tickets', 'Guests', 'Membership Status of Purchaser']}
          rows={table.rows != undefined ? table.rows : []}
        />
      </Card>

      {exportData != 0 && (
        <ExcelExport
          data={exportData}
          fileName={'EventGuests.xlsx'}
          ref={(exporter) => {
            _exporter = exporter;
          }}
        >
          {KEYS.map((item, i) => (
            <ExcelExportColumn field={item} title={HEAD[i]} width={100} />
          ))}
        </ExcelExport>
      )}
    </>
  );

  const handleSetGuests = (guests) => {
    setGuests(guests);
    setGuestList(true);
  };

  const showGuestList = () => {
    return (
      <div>
        <table style={{ borderSpacing: '3vw 1vw' }}>
          <tr style={{ textAlign: 'center' }}>
            <th style={{ fontWeight: '600' }}>Name</th>
            <th style={{ fontWeight: '600' }}>Email</th>
          </tr>
          {guests.map((guest) => (
            <tr>
              <td>{camelToSentence(guest.name)}</td>
              <td>{guest.email}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  };

  return (
    <>
      <Loader show={loading} />
      <FormDialogMax
        show={eventGuestModal}
        onClose={() => seteventGuestModal(false)}
        title={``}
        maxWidth={screenWidth >= 1920 ? 'lg' : 'lg'}
        content={eventMapDetails()}
        // text={"addFcrBidText"}
      />
      <div className='guest-table'>
        <FormDialogMax show={guestList} onClose={() => setGuestList(false)} title={'Guest List'} content={showGuestList()} />
      </div>
    </>
  );
};
