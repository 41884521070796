import React, { useEffect, useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Password } from '../../components/Inputs/Input';
import { TOKEN, DEFAULT_PATH } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import { SET_USER } from '../../context/constants';
import { LoginValidation } from '../../validation/UserValidation';
import { UserService } from '../../services/UserService';
import Loader from '../../components/Loader/Loader';
import { Formik, Field, Form } from 'formik';
import classes from './Login.module.css';
import { NavLink } from 'react-router-dom';
import { notifyWarning, notifySucess } from '../../components/AlertComponent/ToastifyAlert';

function useIsMountedRef() {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
}

export const SignIn = () => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const startLoading = () => isMountedRef.current && setLoading(true);
  const stopLoading = () => isMountedRef.current && setLoading(false);

  useEffect(() => {
    if (!state.isAuthorized)
      UserService.Authenticate(
        () => setLoading(true),
        handleAuthenticateSuccess,
        handleError,
        () => setLoading(false)
      );
  }, []);

  const handleAuthenticateSuccess = ({ data }) => {
    dispatch({
      type: SET_USER,
      payload: data.data,
    });
    history.push(DEFAULT_PATH);
  };

  const handleError = (err) => console.log(err);

  const handleLogin = (values, { setSubmitting }) => {
    const payload = { ...values, admin: true };
    UserService.Login(payload, startLoading, handleLoginSuccess, handleLoginFail, () => setSubmitting(false));
  };

  const handleLoginSuccess = (res) => {
    const token = res.data.data.jwt;
    localStorage.setItem(TOKEN, token);
    if (token) {
      notifySucess('Login sucess');
      dispatch({
        type: SET_USER,
        payload: res.data.data,
      });
      history.push(DEFAULT_PATH);
    }
    console.log(res);
  };

  const handleLoginFail = (error) => {
    console.log(error);
    if (error.response) {
      notifyWarning(error.response.data.data);
    }
    stopLoading();
  };

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <div className={classes.Login}>
      <Loader show={loading} />
      <div className={classes.LoginOverlay}></div>
      <div className={classes.LoginContainer}>
        <div className={classes.Title}>
          <p>
            Admin <br /> Login
          </p>
        </div>
        <div className={classes.Fields}>
          <Formik initialValues={initialValues} validationSchema={LoginValidation} onSubmit={handleLogin}>
            <Form>
              <div className={classes.FormContainer}>
                <div>
                  <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' />
                </div>
                <div>
                  <Password name='password' label='password' />
                </div>
                <div className={classes.Footer}>
                  <NavLink to='/auth/forgetPassword' style={{ cursor: 'pointer' }}>
                    Forgot Password
                  </NavLink>
                </div>
                <div className={classes.FooterButtons}>
                  <div>
                    <button type='submit' disabled={disableSubmit} className={classes.btn_primary}>
                      Submit
                    </button>
                  </div>
                  {/* <div>
                  <NavLink
                    to='/'
                    style={{ cursor: 'pointer' }}
                    className={ window.screen.width >= 600 ? classes.btn_black  :  classes.btn_primary}
                  >
                    Register
                  </NavLink>
                </div> */}
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
