import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Visibility';
import { Field, Form, Formik } from 'formik';
import { Input, DropdownComponent, PhoneInput, DropdownComponent2, DropdownComponentWithState } from '../../components/Inputs/Input';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { spacing } from '@material-ui/system';
import './user.css';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AuthContext } from '../../context/AuthContext';
import { TableComponent } from '../../components/Table/TableComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import Loader from '../../components/Loader/Loader';
import { AddUserFormValidation } from '../../validation/UserValidation';
import { UserService } from '../../services/UserService';
import { UserDetailsModal } from './UserDetailsModal';
import { TransactionTableDetails } from './TransactionTableDetails';
import { Capitalize } from '../../utils/StringHelper';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DeviceHub as DeviceHubIcon,
  DevicesOther as DeviceOtherIcon,
  SearchOutlined,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ROLE, USER_ROLE } from '../../constants';
import { SET_USER } from '../../context/constants';

const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  span: {
    marginLeft: '2vw',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  h2: {
    lineHeight: '2',
    fontSize: '1.5vw',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
  },
  ButtonContainer: {
    display: 'flex',
    gridColumnGap: '0.5vw',
  },
  Button: {
    width: '1.5vw',
    height: '1.5vw',
  },
});
export const Users = () => {
  const { state } = useContext(AuthContext);
  const classes = useStyles();
  const [showUserForm, setshowUserForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [filter, setFilter] = useState('');
  const [table, setTable] = useState({});
  const [userModal, setuserModal] = useState(false);
  const [transactionModal, settransactionModal] = useState(false);
  const [IntialData, setIntialData] = useState({});
  const [exportData, setexportData] = useState([]);
  const [search, setSearch] = useState('');
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [random, setRandom] = useState(0);

  useEffect(() => {
    setPagination({ totalPages: 0, curPage: 1 });
    setoffset(0);
    setRandom(Math.random);
  }, [filter, search]);

  useEffect(() => {
    if (userModal === false) {
      fetchUsers();
    }
  }, [offset, userModal, random]);

  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);

  let _exporter;
  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };

  const handleFetchAll = () => {
    let processedFilter;
    filter === ' ' ? (processedFilter = '') : (processedFilter = filter);
    UserService.ReadAllUser(0, 10, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading, processedFilter, search);
  };
  const handleAllFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;
    if (data.length > 0) {
      setKEYS(Object.keys(data[0]));
      setHEAD(Object.keys(data[0]));
    }
    setexportData([...data]);
  };
  const handleAllFetchError = (err) => {
    console.log(err);
  };

  const fetchUsers = () => {
    let processedFilter;
    filter === ' ' ? (processedFilter = '') : (processedFilter = filter);
    UserService.ReadAllUser(offset, limit, startLoading, handleFetchSuccess, handleFetchError, stopLoading, processedFilter, search);
  };
  const handleFetchSuccess = (res) => {
    let data = res.data.data.data;
    console.log(res);
    let paginate = res.data.data?.pagination?.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));
    const handleView = (item) => (
      <>
        <span
          onClick={() => {
            setuserModal(true);
            setIntialData(item);
          }}
        >
          <PageviewIcon variant='contained' color='primary' />
        </span>

        <span
          className={classes.span}
          onClick={() => {
            settransactionModal(true);
            setIntialData(item);
          }}
        >
          <AttachMoneyIcon variant='contained' color='primary' />
        </span>
      </>
    );

    let rowData = data.map((item, index) => ({
      ...item,
      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      membershipStatus: Capitalize(item.membershipStatus),
      action: handleView(item),
    }));
    let keys = ['Sno', 'firstName', 'lastName', 'email', 'role', 'membershipStatus', 'action'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    console.log(temp);
    setTable(temp);
  };

  const handleFetchError = (res) => {
    console.log(res);
  };

  const handleSubmit = (values) => {
    console.log(values);
    let payload = {
      ...values,
      class: 0,
    };
    UserService.Create(payload, startLoading, handleCreatesuccess, handleCreateError, stopLoading);
  };
  const handleCreatesuccess = (res) => {
    if (res) {
      notifySucess('User Created!');
      setshowUserForm(false);
    }
  };
  const handleCreateError = (err) => {
    console.log(err.response)
    notifyWarning(err.response.data.data);
  };
  const addUserContent = (edit = false) => (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={AddUserFormValidation}
    >
      {(props) => (
        <Form>
          <Grid container direction='column' spacing={4}>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='firstName' label='First Name' placeholder='Jessica' />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='lastName' label='Last Name' placeholder='James' />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div>
                <PhoneInput name='phone' label='Phone' />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <DropdownComponent
                name='role'
                label='Role'
                setFieldValue={props.setFieldValue}
                setFieldTouched={props.setFieldTouched}
                getFieldMeta={props.getFieldMeta}
                values={USER_ROLE}
              />
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' />
              </div>
            </Grid>
            <Grid container spacing={3} className={classes.dialogButtons}>
              <Grid item xs={4}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setshowUserForm(false);
                  }}
                  fullWidth
                  mb={2}
                  variant='contained'
                  color='primary'
                  disabled={false}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className={classes.button} fullWidth variant='contained' color='primary' mb={2} type='submit'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <Loader show={loading} />
      <UserDetailsModal IntialData={IntialData} userModal={userModal} setuserModal={setuserModal} />
      <TransactionTableDetails IntialData={IntialData} transactionModal={transactionModal} settransactionModal={settransactionModal} />
      <FormDialog
        show={showUserForm}
        onClose={() => setshowUserForm(false)}
        title={`${showUserForm !== true ? 'Edit' : 'Create'} User`}
        content={addUserContent()}
        text={showUserForm !== true ? '' : ''}
      />
      <Grid container direction='column'>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
            <div className='top-container'>
              <div className='dropdown-filter'>
                <DropdownComponentWithState
                  label='Role'
                  values={USER_ROLE}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.value);
                  }}
                />
              </div>
              <Formik initialValues={{}} onSubmit={(val) => setSearch(val.search)}>
                {(props) => (
                  <Form>
                    <div className='search-container'>
                      <Input name='search' id='search' label='' placeholder='Search by name..' />
                      <Fab className={classes.fab2} size='small' color='secondary' aria-label='Add' onClick={props.handleSubmit}>
                        <SearchOutlined className={classes.Button} />
                      </Fab>
                      <Typography variant='h1' className={classes.h2}>
                        <div style={{ marginLeft: '2.5vw' }}>Users</div>
                      </Typography>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div>
              <div className={classes.ButtonContainer}>
                {state.user.role !== 'editor' ? (
                  <Fab className={classes.fab} size='small' color='secondary' aria-label='Add'>
                    <PersonAddIcon
                      className={classes.Button}
                      onClick={() => {
                        setshowUserForm(true);
                      }}
                    />
                  </Fab>
                ) : null}
                <Fab className={classes.fab2} size='small' color='secondary' aria-label='Add'>
                  <AssignmentReturnedIcon className={classes.Button} onClick={handleFetchAll} />
                </Fab>
              </div>
              {exportData != 0 && (
                <ExcelExport
                  data={exportData}
                  fileName={'Users.xlsx'}
                  ref={(exporter) => {
                    _exporter = exporter;
                  }}
                >
                  {HEAD.map((item, i) => {
                    if (item === 'jwt' || item === 'password' || item === 'otp') {
                      i++;
                      return;
                    }
                    return <ExcelExportColumn field={item} title={KEYS[i]} width={100} />;
                  })}
                </ExcelExport>
              )}
            </div>
          </div>
          <TableComponent
            pagination={pagination}
            setPagination={setPagination}
            title=''
            inline={''}
            description=''
            keys={table.keys != undefined ? table.keys : []}
            head={['Sno.', 'First Name', 'Last Name', 'Email', 'Role', 'Membership Status', 'Action']}
            rows={table.rows != undefined ? table.rows : []}
          />
        </Grid>
      </Grid>
    </>
  );
};
