import React, { Fragment } from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  background: black;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  opacity: 0.4;
`;

function Loader({ show = false }) {
  const loaderComponent = (
    <Root>
      <CircularProgress m={2} color="secondary" />
    </Root>
  );
  return <Fragment>{Boolean(show) && loaderComponent}</Fragment>;
}

export default Loader;
