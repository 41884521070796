import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { useEffect, useRef, useState } from 'react';
import { MAP_API_KEY } from './config';

const CreateEventMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + MAP_API_KEY + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap onClick={props.onClick} defaultZoom={7} center={props.location}>
      <Marker
        draggable={props.draggable}
        defaultPosition={props.location}
        position={props.location}
        onDragEnd={({ latLng }) => props.setLocation(latLng.toJSON())}
      />
    </GoogleMap>
  );
});

export default CreateEventMapComponent;
