import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { MAP_API_KEY } from './config';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const MapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + MAP_API_KEY + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ defaultLocation, setLocation, location }) => {
  const [markerPosition, setMarkerPosition] = useState(defaultLocation);

  const onMapClick = ({ latLng }) => {
    setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });
    setLocation({ ...location, lat: latLng.lat(), lng: latLng.lng() });
    // setShowModal(false);
  };

  return (
    <GoogleMap defaultZoom={12} defaultCenter={markerPosition} onClick={onMapClick}>
      <Marker position={markerPosition} clickable defaultDraggable={true} />
    </GoogleMap>
  );
});

export default MapComponent;
