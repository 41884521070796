import React from 'react';
import { Card, Dialog, DialogTitle, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    minHeight: '175px',
    height: 'auto',
    // overflow: "hidden",
  },
});

export const FormDialogMax = ({ show = false, text = '', title = '', content = '', onClose = () => {}, maxWidth = 'sm' }) => {
  const classes = useStyles();
  const handleCancel = () => onClose(false);

  return (
    <Card mb={6}>
      <div>
        <Dialog fullWidth open={show} onClose={handleCancel} aria-labelledby='form-dialog-title' scroll='body' maxWidth={maxWidth}>
          {title && (
            <DialogTitle id='form-dialog-title' style={{ textAlign: 'center' }}>
              {title}
            </DialogTitle>
          )}
          <DialogContent className={classes.container}>
            {text && <DialogContentText style={{ marginBottom: '2rem' }}>{text}</DialogContentText>}
            {content}
          </DialogContent>
        </Dialog>
      </div>
    </Card>
  );
};
