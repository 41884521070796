import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Visibility';
import { Field, Form, Formik } from 'formik';
import './coupan.css';
import { Input, DropdownComponent, PhoneInput, DropdownComponent2 } from '../../components/Inputs/Input';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { spacing } from '@material-ui/system';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AuthContext } from '../../context/AuthContext';
import { TableComponent } from '../../components/Table/TableComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import Loader from '../../components/Loader/Loader';
import { AddCouponFormValidation } from '../../validation/CouponValidation';
import { SubscriptionService } from '../../services/SubscriptionService';
import { CouponDetailsModal } from './CouponDetailsModal';
import { Capitalize } from '../../utils/StringHelper';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DeviceHub as DeviceHubIcon,
  DevicesOther as DeviceOtherIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import moment from 'moment';
import { DateSelectorWithoutArrow } from '../../components/DateSelector/DateSelectorWithoutArrow';
import { ROLE } from '../../constants';
import { DatePicker } from '@material-ui/pickers';
const options = ROLE;

const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
    alignItems: 'center',
  },
});
export const Coupon = () => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);

  const [showCouponForm, setShowCoupanForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [table, setTable] = useState({});
  const [Refresh, setRefresh] = useState(null);
  const [sDate, setSdate] = useState(moment());
  const [AllData, setAllData] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  useEffect(() => {
    if (showCouponForm === false) {
      fetchCoupans();
    }
  }, [showCouponForm, Refresh, offset]);
  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 2;
    setoffset(skip);
  }, [pagination]);

  const fetchCoupans = () => {
    SubscriptionService.readAllCoupons(startLoading, handleFetchSuccess, handleFetchError, stopLoading);
  };
  const handleDeleteCoupan = (item) => {
    SubscriptionService.DeleteCoupan(item.id, startLoading, handleDeleteSuccess, handleFetchError, stopLoading);
  };
  const handleDeleteSuccess = (res) => {
    if (res) {
      setRefresh(res);
      notifySucess('Coupon Deleted!');
    }
  };
  const handleView = (item) => (
    <>
      <DeleteIcon
        color='primary'
        onClick={() => {
          handleDeleteCoupan(item);
        }}
      />
    </>
  );
  useEffect(() => {
    let rowData = AllData.map((item, index) => ({
      ...item,

      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * limit + (index + 1),
      redeem_by: moment.unix(item.redeem_by).format('MM/DD/YYYY'),

      action: handleView(item),
    }));
    let keys =
      state.user.role !== 'editor'
        ? ['Sno', 'name', 'percent_off', 'redeem_by', 'max_redemptions', 'times_redeemed', 'action']
        : ['Sno', 'name', 'percent_off', 'redeem_by', 'max_redemptions', 'times_redeemed'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  }, [offset, limit, AllData]);
  const handleFetchSuccess = (res) => {
    let data = res.data.data;
    const totalPage = data.length;
    console.log(totalPage);
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(totalPage / limit),
    }));

    if (data.length > limit) {
      let rawData = [];
      if (offset === 0) {
        for (let i = offset; i < limit; i++) {
          rawData.push(data[i]);
        }

        setAllData(rawData);
      } else {
        for (let i = offset; i < offset + limit && i <= data.length - 1; i++) {
          rawData.push(data[i]);
        }
        setAllData(rawData);
      }
    } else {
      setAllData(data);
    }
  };
  const handleFetchError = (err) => {
    console.log(err);
  };

  const handleSubmit = (values) => {
    let payload = {
      ...values,
      percent_off: parseInt(parseInt(values.percent_off).toFixed(0)),
      max_redemptions: parseInt(parseInt(values.max_redemptions).toFixed(0)),
      redeem_by: moment(sDate).endOf('day').unix(),
    };

    SubscriptionService.GeneateCoupan(payload, startLoading, handleCreatesuccess, handleCreateError, stopLoading);
  };
  const handleCreatesuccess = (res) => {
    if (res) {
      notifySucess('Coupon Created!');
      setShowCoupanForm(false);
    }
  };
  const handleCreateError = (err) => {
    notifyWarning('something went wrong!');
  };

  const addUserContent = (edit = false) => (
    <Formik
      initialValues={{
        name: '',
        percent_off: 0,
        max_redemptions: 0,
        redeem_by: new Date(),
      }}
      onSubmit={handleSubmit}
      validationSchema={AddCouponFormValidation}
    >
      {(props) => (
        <Form>
          <Grid container direction='column' spacing={4}>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='name' label='Coupon code' />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='percent_off' label='Percent off' placeholder='Percent off' />
              </div>
            </Grid>

            <Grid item xs={12} className={classes.container}>
              <div>
                <Input name='max_redemptions' label='Max Redemptions' placeholder='Max Redemptions' />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div className='coupan'>
                <DatePicker disableToolbar variant='inline' label='Redeem By' value={sDate} onChange={(date) => setSdate(date)} />
              </div>
            </Grid>
            <Grid container spacing={3} className={classes.dialogButtons}>
              <Grid item xs={4}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setShowCoupanForm(false);
                  }}
                  fullWidth
                  mb={2}
                  variant='contained'
                  color='primary'
                  disabled={false}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className={classes.button} fullWidth variant='contained' color='primary' mb={2} type='submit'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <Loader show={loading} />
      <FormDialog
        fullWidth={false}
        show={showCouponForm}
        onClose={() => setShowCoupanForm(false)}
        title={`${showCouponForm !== true ? 'Edit' : 'Create'} Coupon`}
        content={addUserContent()}
        text={showCouponForm !== true ? '' : ''}
      />
      <Grid container direction='row' justify='space-around' alignItems='center' spacing={3}>
        <Grid container direction='column'>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
              <div>
                <Typography variant='h1' className={classes.h2}>
                  <div style={{ marginRight: '4.5vw' }}>Coupons</div>
                </Typography>
              </div>
              <div>
                {state.user.role !== 'editor' ? (
                  <Fab className={classes.fab} mx={2} size='small' color='secondary' aria-label='Add'>
                    <AddIcon
                      onClick={() => {
                        setShowCoupanForm(true);
                      }}
                    />
                  </Fab>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TableComponent
            pagination={pagination}
            setPagination={setPagination}
            title=''
            inline={''}
            description=''
            keys={table.keys != undefined ? table.keys : []}
            head={
              state.user.role !== 'editor'
                ? ['Sno', 'Coupan Code', 'Percent Off', 'Redeem By', 'Max Redemptions', 'Times Redeemed', 'Action']
                : ['Sno', 'Coupan Code', 'Percent Off', 'Redeem By', 'Max Redemptions', 'Times Redeemed']
            }
            rows={table.rows != undefined ? table.rows : []}
          />
        </Grid>
      </Grid>
    </>
  );
};
