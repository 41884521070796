import Axios from 'axios';

// export const BASE_URL = 'http://localhost:10002/api/';
export const BASE_URL = 'https://w3u4tpit8l.execute-api.us-east-1.amazonaws.com/dev/api/';

export const generateOtp = (payload) => {
  return Axios.post(`${BASE_URL}forgotPassword`, payload);
};

export const verifyOTP = (payload) => {
  return Axios.post(`${BASE_URL}verifyOTP`, payload);
};

export const resetPassword = (authToken, payload) => {
  const config = {
    params: {
      authToken,
    },
  };
  return Axios.post(`${BASE_URL}resetPassword`, payload, config);
};
