import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import { FormDialogMax } from '../../components/FormDialog/FormDialogMax';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import './user.css';
import { TransactionService } from '../../services/TransactionService';
import { TableComponent } from '../../components/Table/TableComponent';
import { ROLE, TRANSACTION_TYPES, TYPE } from '../../constants';
import { DropdownComponentWithState } from '../../components/Inputs/Input';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';

export const TransactionTableDetails = ({ IntialData, transactionModal, settransactionModal }) => {
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [screenWidth, setScreenwidth] = useState(window.screen.width);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [filter, setFilter] = useState('none');
  const [table, setTable] = useState({});
  const [dates, setDates] = useState({
    startDate: moment().clone().startOf('month').startOf('day'),
    endDate: moment(),
  });
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  console.log(pagination);

  useEffect(() => {
    setScreenwidth(window.screen.width);
  }, [window.screen.width]);

  useEffect(() => {
    if (IntialData.id != undefined) {
      fetchData();
    }
  }, [IntialData, filter, offset]);

  const fetchData = () => {
    const formattedDates = {
      startDate: moment(dates.startDate).startOf('day').toISOString(),
      endDate: moment(dates.endDate).endOf('day').toISOString(),
    };
    console.log(formattedDates);
    TransactionService.ReadById(
      offset,
      limit,
      startLoading,
      handleFetchTransactionSuccess,
      handleFetchTransactionError,
      stopLoading,
      filter,
      IntialData.id,
      formattedDates
    );
  };

  const handleFetchTransactionSuccess = (res) => {
    let paginate = res.data.data.pagination.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));

    let data = res.data.data.data;

    const rowData = data.map((item, index) => ({
      ...item,
      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      amount: (
        <>
          <span>&#36;</span> {`${(parseInt(item.amount) / 100).toFixed(0)}`}
        </>
      ),
      createdAt: `${new Date(item.createdAt).toLocaleDateString()} ${new Date(item.createdAt).toLocaleTimeString()}`,
    }));

    let keys = ['Sno', 'type', 'stripePaymentId', 'amount', 'description', 'createdAt'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  };

  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  const handleFetchTransactionError = (err) => {
    console.log(err);
  };

  const trasactionDetails = () => (
    <>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={3}>
        <Grid item xs={2}>
          <DropdownComponentWithState
            label='Type'
            values={TRANSACTION_TYPES}
            value={filter}
            onChange={(e) => {
              setFilter(e.value);
            }}
          />
        </Grid>
        <Grid container xs={6} direction='row' justify='flex-end' alignItems='center' spacing={1}>
          <Grid item xs={4}>
            <DatePicker
              disableToolbar
              variant='inline'
              label='From'
              value={dates.startDate}
              onChange={(date) => setDates({ ...dates, startDate: date })}
              format='YYYY-MM-DD'
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              disableToolbar
              variant='inline'
              label='To'
              value={dates.endDate}
              onChange={(date) => setDates({ ...dates, endDate: date })}
              format='YYYY-MM-DD'
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => fetchData()} color='primary'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableComponent
        pagination={pagination}
        setPagination={setPagination}
        title='Transactions Table'
        inline={null}
        description=''
        keys={table.keys != undefined ? table.keys : []}
        head={['S No', 'Type', 'Stripe Payment Id', 'Amount', 'Description', 'Created At']}
        rows={table.rows != undefined ? table.rows : []}
      />
    </>
  );
  return (
    <>
      <Loader show={loading} />
      <FormDialogMax
        show={transactionModal}
        onClose={() => settransactionModal(false)}
        maxWidth={screenWidth >= 1920 ? 'md' : 'md'}
        content={trasactionDetails()}
      />
    </>
  );
};
