import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import { FormDialogMax } from '../../components/FormDialog/FormDialogMax';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { EventService } from '../../services/EventService';
import { TableComponent } from '../../components/Table/TableComponent';
import { ROLE, TYPE } from '../../constants';
import { spacing } from '@material-ui/system';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  span: {
    marginLeft: '2vw',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  h2: {
    zIndex: 1,
    position: 'absolute',
    right: '33.297vw',
    margin: '0.791vw 4.006vw 0 0',
  },
  filter: {
    zIndex: 1,
    position: 'absolute',
    right: '68.297vw',
    margin: '4.91vw 2.006vw 0 0',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  fab: {},
  fab2: {
    zIndex: 1,
    position: 'absolute',
    right: '5%',
    margin: '0.791vw 4.006vw 0 0',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
});
export const EventMapModal = ({ IntialData, eventMapModal, seteventMapModal }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [screenWidth, setScreenwidth] = useState(window.screen.width);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [filter, setFilter] = useState('none');
  const [table, setTable] = useState({});
  const [exportData, setexportData] = useState([]);
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  useEffect(() => {
    setScreenwidth(window.screen.width);
  }, [window.screen.width]);
  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);
  let _exporter;
  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };
  const handleFetchAll = () => {
    EventService.EventMapping(IntialData.id, 0, 0, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading);
  };
  const handleAllFetchSuccess = (res) => {
    let data = res.data.data.data;
    console.log(data);
    let rawData = data.map((item, index) => ({
      ...item,
      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      name: `${item.User.firstName} ${item.User.middleName != null ? item.User.middleName : ' '} ${item.User.lastName}`,
      email: item.User.email,
      customerId: item.User.customerId,
      membershipStatus: item.User.membershipStatus,
      stripePaymentId: item.Transaction.stripePaymentId,

      amount: item.Transaction.amount,

      createdAt: new Date(item.Transaction.createdAt).toLocaleDateString(),
    }));

    let keys = ['Sno', 'name', 'email', 'customerId', 'reservedCount', 'membershipStatus', 'stripePaymentId', 'amount', 'createdAt'];
    setKEYS(keys);
    setHEAD(['Sno', 'Name', 'Email', 'Customer Id', 'Tickets Purchased', 'Membership Status', 'Stripe Payment Id', 'Amount', 'CreatedAt']);
    setexportData(rawData);
  };
  const handleAllFetchError = (err) => {
    console.log(err);
  };
  useEffect(() => {
    if (IntialData.id != undefined) {
      EventService.EventMapping(
        IntialData.id,
        offset,
        limit,
        startLoading,
        handleFetchEventMappSuccess,
        handleFetchEventMappError,
        stopLoading
      );
    }
  }, [IntialData, offset]);

  const handleFetchEventMappSuccess = (res) => {
    let paginate = res.data.data.pagination.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));
    let data = res.data.data.data;
    console.log(data);
    const rowData = data.map((item, index) => ({
      ...item,
      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      name: `${item.User.firstName} ${item.User.middleName != null ? item.User.middleName : ''}${item.User.lastName}`,
      email: item.User.email,
      customerId: item.User.customerId,
      membershipStatus: item.User.membershipStatus,
      stripePaymentId: item.Transaction.stripePaymentId,
      amount: (
        <>
          {' '}
          <span>&#36;</span> {`${(parseInt(item.Transaction.amount) / 100).toFixed(0)}`}
        </>
      ),

      createdAt: `${new Date(item.Transaction.createdAt).toLocaleDateString()} ${new Date(
        item.Transaction.createdAt
      ).toLocaleTimeString()}`,
    }));
    let keys = ['Sno', 'name', 'email', 'customerId', 'reservedCount', 'membershipStatus', 'stripePaymentId', 'amount', 'createdAt'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  };

  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  const handleFetchEventMappError = (err) => {
    console.log(err);
  };

  const eventMapDetails = () => (
    <>
      <Grid conatiner direction='row' justify='space-around' alignItems='center' spacing={3}>
        <Grid item={6} style={{ textAlign: 'right' }}>
          <Fab className={classes.fab} mx={2} size='medium' color='secondary' aria-label='Add'>
            <AssignmentReturnedIcon onClick={handleFetchAll} />
          </Fab>
        </Grid>
      </Grid>
      <Card>
        <TableComponent
          pagination={pagination}
          setPagination={setPagination}
          title='Ticket Sold Details'
          inline={null}
          description=''
          keys={table.keys != undefined ? table.keys : []}
          head={[
            'Sno',
            'Name',
            'Email',
            'Customer Id',
            'Tickets Purchased',
            'Membership Status',
            'Stripe Payment Id',
            'Amount',
            'CreatedAt',
          ]}
          rows={table.rows != undefined ? table.rows : []}
        />
      </Card>

      {exportData != 0 && (
        <ExcelExport
          data={exportData}
          fileName={'TicketSoldDetails.xlsx'}
          ref={(exporter) => {
            _exporter = exporter;
          }}
        >
          {KEYS.map((item, i) => (
            <ExcelExportColumn field={item} title={HEAD[i]} width={100} />
          ))}
        </ExcelExport>
      )}
    </>
  );
  return (
    <>
      <Loader show={loading} />
      <FormDialogMax
        show={eventMapModal}
        onClose={() => seteventMapModal(false)}
        title={``}
        maxWidth={screenWidth >= 1920 ? 'lg' : 'lg'}
        content={eventMapDetails()}
        // text={"addFcrBidText"}
      />
    </>
  );
};
