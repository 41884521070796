import { HttpClient } from '../utils/httpClient';

const PATH = {
  readAll: '/transactions',
  readById: '/user',
};

const ReadAll = (offset = 0, limit = 10, dates, start, callback, error, next, type = null) => {
  start();
  return HttpClient.get(
    `${PATH.readAll}?${type != 'none' ? `type=${type}` : ''}${offset === 0 && limit === 0 ? '' : `&offset=${offset}&limit=${limit}`}&${
      dates.startDate ? `from=${dates.startDate}&to=${dates.endDate}` : null
    }`
  )
    .then(callback)
    .catch(error)
    .finally(next);
};

const ReadById = (offset = 0, limit = 10, start, callback, error, next, type = null, id, dates) => {
  start();
  return HttpClient.get(
    `${PATH.readById}/${id}/transactions?${type != 'none' ? `type=${type}` : ''}&${
      offset === 0 && limit === 0 ? '' : `&offset=${offset}&limit=${limit}`
    }&${dates.startDate ? `from=${dates.startDate}&to=${dates.endDate}` : null}`
  )
    .then(callback)
    .catch(error)
    .finally(next);
};

export const TransactionService = {
  ReadAll,
  ReadById,
};
