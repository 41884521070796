import React, { useState, memo, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { NavLink as RouterNavLink, useHistory, withRouter } from 'react-router-dom';
import { darken } from 'polished';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../vendor/perfect-scrollbar.css';
import { spacing } from '@material-ui/system';

import {
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  IconButton,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import routes from '../../routes/index';
import { Layers, LogOut, Power } from 'react-feather';
import { AuthContext } from '../../context/AuthContext';
import { UserService } from '../../services/UserService';
import { LOGIN_PATH, TOKEN } from '../../constants';
import { REMOVE_USER, SET_USER } from '../../context/constants';
import Loader from '../Loader/Loader';
import ProfileIcon from '../../assets/profile.jpg';
import { Capitalize } from '../../utils/StringHelper';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: '0.9vw';
  padding-right: '0.9vw';

  ${(props) => props.theme.breakpoints.up('mobile')} {
    min-height: 64px;
  }
`;

const BrandIcon = styled(Layers)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: '0.9vw';
  padding-right: '0.9vw';
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: ${'20px'};
    height: ${'20px'};
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: ${'20px'};
    height: ${'20px'};
    opacity: 0.5;
    margin: 0 0 0 ${(props) => props.theme.spacing(4)}px;
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11vw;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20vw;
  position: absolute;
  right: 12vw;
  top: 8vw;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const UserName = styled.div`
  padding: 0 2vw;
  background: #800e12;
  color: white;
  font-weight: 500;
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  grid-column-gap: 0.81vw;
  z-index: 5;
  padding-top: 0.5vw;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

function SidebarCategory({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
}

function SidebarLink({ name, to, icon, badge }) {
  return (
    <Link button dense component={NavLink} exact to={to} activeClassName='active'>
      {icon}
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
}

const Sidebar = ({ classes, location, ...rest }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AuthContext);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/' ? true : false;

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const triggerLogout = () => {
    UserService.Logout(
      () => setLoading(true),
      handleLogout,
      handleLogout,
      () => {
        setLoading(false);
        history.push(LOGIN_PATH);
      }
    );
  };

  const handleLogout = (res) => {
    dispatch({ type: REMOVE_USER });
    localStorage.removeItem(TOKEN);
  };

  const handleProfileChange = () => {
    history.push('/profile');
  };

  console.log(state);

  return (
    <>
      <Loader show={loading} />
      <Drawer variant='permanent' {...rest} style={{ width: '20vw' }} className='my-sidebar '>
        <Brand>
          <BrandIcon className='brand-icon' /> <Box ml={1}>Harvard Club</Box>
        </Brand>
        <UserName onClick={handleProfileChange}>
          <div>
            <img style={{ height: '3vw', width: '3vw' }} src={ProfileIcon} alt='' />
          </div>
          <div>
            <div>Username: {Capitalize(state.user.firstName) + ' ' + Capitalize(state.user.lastName)}</div>
            <div>Role: {Capitalize(state.user.role)}</div>
          </div>
        </UserName>
        <Scrollbar>
          <List disablePadding>
            <Items>
              {routes.map((category, index) => (
                <React.Fragment key={index + 1}>
                  {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                  {category.children ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                      />

                      <Collapse in={openRoutes[index]} timeout='auto' unmountOnExit>
                        {category.children.map((route, index) => {
                          return (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                              onClick={() => toggle(index)}
                            />
                          );
                        })}
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeClassName='active'
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={category.badge}
                    />
                  )}
                </React.Fragment>
              ))}
            </Items>
          </List>
        </Scrollbar>
        <SidebarFooter className='sidebar-footer'>
          <IconButton aria-haspopup='true' color='white' onClick={triggerLogout}>
            <Power className='power-button ' /> <div className='text'>Logout</div>
          </IconButton>
        </SidebarFooter>
      </Drawer>
    </>
  );
};

export default withRouter(Sidebar);
