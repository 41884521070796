import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Visibility';
import { TableComponent } from '../../components/Table/TableComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import Loader from '../../components/Loader/Loader';
import { SchoolService } from '../../services/SchoolService';
import { spacing } from '@material-ui/system';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { ShowSchoolDetails } from './ShowSchoolDetails';
import { Capitalize } from '../../utils/StringHelper';
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
    alignItems: 'center',
  },
});
export const School = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [table, setTable] = useState({});
  const [schoolModal, setschoolModal] = useState(false);
  const [IntialData, setIntialData] = useState({});
  const [exportData, setexportData] = useState([]);
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  console.log(KEYS);
  useEffect(() => {
    fetchUsers();
  }, [offset, limit]);
  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);
  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);

  //this function will call for excel data download
  let _exporter;
  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };

  const handleFetchAll = () => {
    SchoolService.ReadAll(0, 0, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading);
  };
  const handleAllFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;
    if (data.length > 0) {
      setKEYS(Object.keys(data[0]));
      setHEAD(Object.keys(data[0]));
    }
    setexportData([...data]);
  };
  const handleAllFetchError = (err) => {
    console.log(err);
  };
  const fetchUsers = () => {
    SchoolService.ReadAll(offset, limit, startLoading, handleFetchSuccess, handleFetchError, stopLoading);
  };
  const handleFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;
    let paginate = res.data.data.pagination.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));
    const handleView = (item) => (
      <>
        <PageviewIcon
          onClick={() => {
            setschoolModal(true);
            setIntialData(item);
          }}
        />
      </>
    );

    let rowData = data.map((item, index) => ({
      ...item,
      sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      createdAt: `${new Date(item.createdAt).toLocaleDateString()} `,
      action: handleView(item),
    }));
    let keys = ['sno', 'firstName', 'lastName', 'email', 'workPhone', 'createdAt', 'action'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  };
  const handleFetchError = (res) => {
    console.log(res);
  };
  return (
    <>
      <Loader show={loading} />
      <ShowSchoolDetails IntialData={IntialData} schoolModal={schoolModal} setschoolModal={setschoolModal} />
      <Grid container direction='row' justify='space-around' alignItems='center' spacing={3}>
        <Grid container direction='column'>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
              <div>
                <Typography variant='h1' className={classes.h2}>
                  <div style={{ marginRight: '4.5vw' }}>Schools</div>
                </Typography>
              </div>
              <div>
                <Fab className={classes.fab} mx={2} size='small' color='secondary' aria-label='Add'>
                  <AssignmentReturnedIcon onClick={handleFetchAll} />
                </Fab>
                {exportData != 0 && (
                  <ExcelExport
                    data={exportData}
                    fileName={'Schools.xlsx'}
                    ref={(exporter) => {
                      _exporter = exporter;
                    }}
                  >
                    {KEYS.map((item, i) => (
                      <ExcelExportColumn field={item} title={HEAD[i]} width={100} />
                    ))}
                  </ExcelExport>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TableComponent
            pagination={pagination}
            setPagination={setPagination}
            title=''
            inline={''}
            description=''
            keys={table.keys != undefined ? table.keys : []}
            head={['S.No', 'First Name', 'Last Name', 'Email', 'Phone', 'Created At', 'Action']}
            rows={table.rows != undefined ? table.rows : []}
          />
        </Grid>
      </Grid>
    </>
  );
};
