import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Capitalize } from '../../utils/StringHelper';
import classes from './Input.module.css';
import MEDitor from '@uiw/react-md-editor';

export const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='incomp'>
      <div className={classes.InputContainer}>
        <div className={classes.Label}>
          {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
        </div>
        <div>
          <input type='text' {...field} {...props} required={false} disabled={props.disabled} />
          {props.submitCount || (meta.touched && meta.error) ? <div className={classes.error_msg}>{meta.error}</div> : null}
        </div>
      </div>
    </div>
  );
};

export const Input2 = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer2}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div>
        <input type='text' {...field} {...props} required={false} disabled={props.disabled} />
        {meta.touched && meta.error ? <div className={classes.error_msg}>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const Input3 = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer3}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div>
        <input type='text' {...field} {...props} required={false} disabled={props.disabled} />
        {meta.touched && meta.error ? <div className={classes.error_msg}>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const EventInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.EventInput}>
      <div className={classes.Label}>{Capitalize(label)}</div>
      <div>
        <input type='text' {...field} {...props} required={false} disabled={props.disabled} />
        {meta.touched && meta.error ? <div className={classes.error_msg}>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const PhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PhoneInput}>
        <div className={classes.CountryCode}>
          +1
          {/* <i class="fa fa-caret-down" aria-hidden="true"></i> */}
        </div>
        <input type='text' placeholder='9876543120' {...field} {...props} required={false} />
        {meta.touched && meta.error ? <div className={classes.error_msg}>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const DropdownComponent = ({ label, ...props }) => {
  const meta = props.getFieldMeta(props.name);
  const [value, setValue] = useState(props.defaultValue || props.values[0]);
  useEffect(() => {
    if (!props.defaultValue) props.setFieldValue(props.name, props.values[0].value);
  }, []);

  const handleDropdownChange = (e) => {
    props.setFieldValue(props.name, e.value);
    setValue(e);
  };
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.Dropdown}>
        <Dropdown
          value={value}
          options={props.values}
          onChange={handleDropdownChange}
          placeholder='Select an option'
          onFocus={() => props.setFieldTouched(props.name, true)}
          {...props}
        />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const DropdownComponentWithState = ({ label, values, value, onChange }) => {
  return (
    <div className={classes.InputContainer + ' dropdown-filter ' + classes.DropdownWithState}>
      <div className={classes.Label}>{Capitalize(label)}</div>
      <div className={classes.Dropdown}>
        <Dropdown value={value} options={values} onChange={onChange} />
      </div>
    </div>
  );
};

export const DropdownComponent2 = ({ label, ...props }) => {
  const options = ['one', 'two', 'three'];
  const meta = props.getFieldMeta(props.name);
  const handleDropdownChange = (e) => {
    if (props.setDropdownVal != null) {
      if (e.value != undefined) {
        if (props.type != undefined) {
          props.setDropdownVal((prev) => [...prev, e.value]);
        } else {
          props.setDropdownVal(e.value);
          props.setFieldValue(props.name, e.value);
        }
      } else {
        props.setDropdownVal(e);
        props.setFieldValue(props.name, e);
      }
    }
  };
  return (
    <div className={classes.InputContainer2}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.Dropdown}>
        <Dropdown
          value={props.DropdownVal != null ? props.DropdownVal : null}
          options={props.values || options}
          onChange={handleDropdownChange}
          placeholder='Select an option'
          onFocus={() => props.setFieldTouched(props.name, true)}
          disabled={props.disabled}
        />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const ClassDropdown = ({ label, ...props }) => {
  const meta = props.getFieldMeta(props.name);
  const handleDropdownChange = (e) => {
    if (props.setDropdownVal != null) {
      if (e.value != undefined) {
        if (props.type != undefined) {
          props.setDropdownVal((prev) => [...prev, e.value]);
        } else {
          props.setDropdownVal(e.value);
          props.setFieldValue(props.name, e.value);
        }
      } else {
        props.setDropdownVal(e);
        props.setFieldValue(props.name, e);
      }
    }
  };
  let [defaultValue] = props.values.filter((value) => value.value === props.defaultValue);
  console.log(defaultValue);
  return (
    <div className={classes.InputContainer2}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.Dropdown}>
        <Dropdown
          value={props.defaultValue != null ? defaultValue : null}
          options={props.values}
          onChange={handleDropdownChange}
          placeholder='Select an option'
          onFocus={() => props.setFieldTouched(props.name, true)}
          disabled={props.disabled}
        />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const RadioButtons = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  useEffect(() => {
    if (!props.defaultValue) props.setFieldValue(props.name, props.options[0].value);
  }, []);
  const handleChange = (e) => {
    props.setFieldValue(props.name, e.target.value);
    if (props.onChange) props.onChange();
  };
  return (
    <div className={classes.RadioButtons}>
      {props.options.map((option, i) => (
        <div class={classes.radioItem}>
          <input
            type='radio'
            {...field}
            id={option.value}
            value={option.value}
            checked={props.values[field.name] ? props.values[field.name] === option.value : i === 0}
            onChange={handleChange}
          />
          <label for={option.value}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.TextArea}>
        <textarea {...field} {...props} required={false} />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const MdEditor = ({ label, ...props }) => {
  const meta = props.getFieldMeta(props.name);
  const handleMdChange = (e) => {
    props.setFieldValue(props.name, e);
  };

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.TextArea} style={{ marginTop: '1vw' }}>
        <MEDitor
          {...props}
          height={200}
          value={props.markdown}
          onChange={handleMdChange}
          onFocus={() => props.setFieldTouched(props.name, true)}
        />

        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const Password = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [type, setType] = useState('password');

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PasswordContainer}>
        <input type={type} {...field} {...props} required={false} />
        {type === 'password' ? (
          <i
            class='fa fa-eye'
            aria-hidden='true'
            style={{ fontSize: '1vw', color: 'darkgrey', position: 'absolute', right: '4.5vw' }}
            onClick={() => setType('text')}
          />
        ) : (
          <i
            class='fa fa-eye-slash'
            aria-hidden='true'
            style={{ fontSize: '1vw', color: 'darkgrey', position: 'absolute', right: '4.5vw' }}
            onClick={() => setType('password')}
          />
        )}
      </div>
      {meta.touched && meta.error ? <div className={classes.error_msg}>{meta.error}</div> : null}
    </div>
  );
};

const strongRegex = new RegExp('^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$');
/* ---STRONG REGEX-----
(?=.*[A-Z].*[A-Z])        Ensure string has two uppercase letters.
(?=.*[!@#$&*])            Ensure string has one special case letter.
(?=.*[0-9].*[0-9])        Ensure string has two digits.
(?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
.{8}                      Ensure string is of length 8. 
*/

const mediumRegex = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$');

export const PasswordWithStrength = ({ label, setPasswordStrength, ...props }) => {
  const [field] = useField(props);
  const [type, setType] = useState('password');

  const handlePasswordChanged = (e) => {
    const password = e.target.value;
    if (strongRegex.test(password)) setPasswordStrength('strong');
    else if (mediumRegex.test(password)) setPasswordStrength('medium');
    else setPasswordStrength('weak');
  };

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PasswordContainer}>
        <input type={type} {...field} {...props} required={false} onKeyUp={handlePasswordChanged} />
        {type === 'password' ? (
          <i class='fa fa-eye' aria-hidden='true' onClick={() => setType('text')} />
        ) : (
          <i class='fa fa-eye-slash' aria-hidden='true' onClick={() => setType('password')} />
        )}
      </div>
    </div>
  );
};
