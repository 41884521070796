import { HttpClient } from "../utils/httpClient";

const PATH = {
  readAll: "/volunteer",
};

const ReadAll = (offset = 0, limit = 10, start, callback, error, next) => {
  start();
  return HttpClient.get(
    `${PATH.readAll}?${
      offset === 0 && limit === 0 ? null : `offset=${offset}&limit=${limit}`
    }`
  )
    .then(callback)
    .catch(error)
    .finally(next);
};

export const VolunteerService = {
  ReadAll,
};
