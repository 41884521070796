import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { FormDialogMax } from "../../components/FormDialog/FormDialogMax";
import {
  Grid,
  makeStyles,
  Button,
  Fab as MuiFab,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { Input3 } from "../../components/Inputs/Input";

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "red",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0.234vw",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    float: "right",
    marginBottom: "2vw",
    marginTop: "2vw",
  },
});
export const ShowSchoolDetails = ({
  schoolModal,
  setschoolModal,
  IntialData,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenwidth] = useState(window.screen.width);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const [initialValues, setinitialValues] = useState({});

  useEffect(() => {
    let temp = IntialData;
    for (let [key, value] of Object.entries(IntialData)) {
      if (value === null) {
        temp[key] = "--";
      }
    }
    setinitialValues(temp);
  }, [IntialData]);
  useEffect(() => {
    setScreenwidth(window.screen.width);
  }, [window.screen.width]);

  const volunteerContent = () => (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={{}}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="affiliation"
                    label="Affiliation"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="address"
                    label="Address"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="city"
                    label="City"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="company"
                    label="Company"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="concentration"
                    label="Concentration"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="discipline"
                    label="Discipline"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="gradDegree"
                    label="Grad Degree"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="gradYear"
                    label="Grad Year"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="hobbies"
                    label="Hobbies"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="homePhone"
                    label="Home Phone"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="jobTitle"
                    label="Job Title"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="noOfInterviews"
                    label="No of Interviews"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="state"
                    label="State"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="undergradDegree"
                    label="Undergrad Degree"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="year"
                    label="Year"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Input3
                    name="yrsOfInterviewing"
                    label="Years Of Interviewing"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginBottom: "4vw" }}>
                  <Input3
                    name="zipcode"
                    label="Zipcode"
                    placeholder="test placeholder"
                    disabled
                  />
                </div>
              </Grid>

              {/* <Grid item xs={12}>
                <Button
                  // className={classes.button}
                  fullWidth
                  variant="contained"
                  color="primary"
                  mb={2}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <>
      <Loader show={loading} />
      <FormDialogMax
        show={schoolModal}
        onClose={() => setschoolModal(false)}
        title={`Details`}
        maxWidth={screenWidth >= 1920 ? "lg" : "md"}
        content={volunteerContent()}
        // text={"addFcrBidText"}
      />
    </>
  );
};
