import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Grid, makeStyles, Card, CardHeader, CardContent, Fab as MuiFab, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Visibility';
import { Field, Form, Formik } from 'formik';
import { Input, DropdownComponent, PhoneInput, DropdownComponent2, DropdownComponentWithState } from '../../components/Inputs/Input';
import { DropDownComponentMaterial } from '../../components/DropDownComponent/DropDownComponent';
import { spacing } from '@material-ui/system';
import './user.css';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AuthContext } from '../../context/AuthContext';
import { TableComponent } from '../../components/Table/TableComponent';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import Loader from '../../components/Loader/Loader';
import { AddUserFormValidation } from '../../validation/UserValidation';
import { TransactionService } from '../../services/TransactionService';

import { Capitalize } from '../../utils/StringHelper';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DeviceHub as DeviceHubIcon,
  DevicesOther as DeviceOtherIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { notifySucess, notifyWarning } from '../../components/AlertComponent/ToastifyAlert';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { ROLE, TRANSACTION_TYPES, TYPE } from '../../constants';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { deepPurple } from '@material-ui/core/colors';
const options = ROLE;

const defaultOption = options[0];
const Fab = styled(MuiFab)(spacing);
const useStyles = makeStyles({
  cardTitleWhite: {
    color: 'red',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0.234vw',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    float: 'right',
    marginBottom: '2vw',
    marginTop: '2vw',
  },
  pagination: {
    marginLeft: 'auto',
    position: 'fixed',
    right: '2%',
    bottom: '2%',
  },
  dialogButtons: {
    justifyContent: 'space-around',
  },
  Head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1vw',
    alignItems: 'center',
  },
  DatePickers: {
    display: 'flex',
    gridColumnGap: '1vw',
    width: '23vw',
    margin: '0 1.3vw',
    justifyContent: 'flex-end',
  },
  h2: {
    fontSize: '1.7vw',
  },
});
export const Transaction = () => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);
  const [showUserForm, setshowUserForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const [role, setRole] = useState('editor');
  const [filter, setFilter] = useState('none');
  const [table, setTable] = useState({});
  const [userModal, setuserModal] = useState(false);
  const [IntialData, setIntialData] = useState({});
  const [FILENAME, setFILENAME] = useState('');
  const [exportData, setexportData] = useState([]);
  const [HEAD, setHEAD] = useState([]);
  const [KEYS, setKEYS] = useState([]);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const [dates, setDates] = useState({
    startDate: moment().clone().startOf('month'),
    endDate: moment(),
  });

  useEffect(() => {
    if (userModal === false) {
      fetchUsers();
    }
  }, [offset, limit, userModal, filter]);

  useEffect(() => {
    let skip = pagination.curPage === 1 ? 0 : (pagination.curPage - 1) * 10;
    setoffset(skip);
  }, [pagination]);

  useEffect(() => {
    if (exportData.length > 0) {
      exportToExcel();
    }
  }, [exportData]);

  let _exporter;
  const exportToExcel = () => {
    if (_exporter != undefined) {
      _exporter.save();
    }
  };

  const handleFetchAll = () => {
    TransactionService.ReadAll(0, 0, dates, startLoading, handleAllFetchSuccess, handleAllFetchError, stopLoading, filter);
  };

  const handleAllFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;

    if (data.length > 0) {
      const processedData = { ...data[0], ...data[0].User };
      delete processedData.User;
      delete processedData.id;
      setKEYS(Object.keys(processedData));
      setHEAD(Object.keys(processedData));
    }
    let dataToExport = data.map((eachData) => {
      return { ...eachData, ...eachData.User, amount: eachData.amount / 100 };
    });
    setexportData(dataToExport);
  };
  const handleAllFetchError = (err) => {
    console.log(err);
  };

  const fetchUsers = () => {
    TransactionService.ReadAll(offset, limit, dates, startLoading, handleFetchSuccess, handleFetchError, stopLoading, filter);
  };

  const handleFetchSuccess = (res) => {
    console.log(res);
    let data = res.data.data.data;

    let paginate = res.data.data.pagination.totalCount;

    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(paginate / 10),
    }));
    const handleView = (item) => (
      <>
        <PageviewIcon
          onClick={() => {
            setuserModal(true);
            setIntialData(item);
          }}
        />
      </>
    );

    let rowData = data.map((item, index) => ({
      ...item,
      Sno: pagination.curPage === 1 ? index + 1 : (pagination.curPage - 1) * 10 + (index + 1),
      name: `${item.User.firstName} ${item.User.middleName != null ? item.User.middleName : ' '}${item.User.lastName}`,
      customerId: item.User.customerId,
      membershipStatus: item.User.membershipStatus,
      email: item.User.email,
      amount: (
        <>
          <span>&#36;</span> {`${(parseInt(item.amount) / 100).toFixed(2)}`}
        </>
      ),
      createdAt: `${new Date(item.createdAt).toLocaleDateString()} ${new Date(item.createdAt).toLocaleTimeString()}`,
    }));
    let keys = ['Sno', 'name', 'email', 'stripePaymentId', 'type', 'amount', 'description', 'customerId', 'membershipStatus', 'createdAt'];
    let temp = {};
    temp.keys = keys;
    temp.rows = rowData;
    setTable(temp);
  };
  const handleFetchError = (res) => {
    console.log(res);
  };

  const handleSubmit = (values) => {
    console.log(values);
    let payload = {
      ...values,
    };
    TransactionService.Create(payload, startLoading, handleCreatesuccess, handleCreateError, stopLoading);
  };

  const handleCreatesuccess = (res) => {
    if (res) {
      notifySucess('User Created!');
      setshowUserForm(false);
    }
  };
  const handleCreateError = (err) => {
    notifyWarning('something went wrong!');
  };

  return (
    <>
      <Loader show={loading} />

      <div style={{ width: '78.8vw' }}>
        <div className={classes.Head} style={{ display: 'flex', margin: '1vw 1.3vw' }}>
          <div>
            <div>
              <DropdownComponentWithState
                label='Type'
                values={TRANSACTION_TYPES}
                value={filter}
                onChange={(e) => {
                  setFilter(e.value);
                }}
              />
            </div>
          </div>
          <div>
            <Typography variant='h1' className={classes.h2}>
              <div>Transactions</div>
            </Typography>
          </div>
          <div className={classes.DatePickers}>
            <div>
              <DatePicker
                disableToolbar
                variant='inline'
                label='From'
                value={dates.startDate}
                onChange={(date) => setDates({ ...dates, startDate: date })}
                format='YYYY-MM-DD'
              />
            </div>
            <div>
              <div>
                <DatePicker
                  disableToolbar
                  variant='inline'
                  label='To'
                  value={dates.endDate}
                  onChange={(date) => setDates({ ...dates, endDate: date })}
                  format='YYYY-MM-DD'
                />
              </div>
            </div>
            <div>
              <Button color='primary' onClick={fetchUsers}>
                Filter
              </Button>
            </div>
            <div>
              <Fab className={classes.fab2} mx={2} size='small' color='secondary' aria-label='Add'>
                <AssignmentReturnedIcon onClick={handleFetchAll} />
              </Fab>
              {exportData != 0 && (
                <ExcelExport
                  data={exportData}
                  fileName={'Transactions.xlsx'}
                  ref={(exporter) => {
                    _exporter = exporter;
                  }}
                >
                  {HEAD.map((item, i) => {
                    console.log(item, HEAD, KEYS);
                    return <ExcelExportColumn field={item} title={KEYS[i]} width={100} />;
                  })}
                </ExcelExport>
              )}
            </div>
          </div>
        </div>
        <TableComponent
          pagination={pagination}
          setPagination={setPagination}
          title=''
          inline={''}
          description=''
          keys={table.keys != undefined ? table.keys : []}
          head={[
            'Sno',
            'Name',
            'Email',
            'Stripe PaymentId',
            'Payment Type',
            'Amount',
            'Description',
            'CustomerId',
            'Membership Status',
            'Created At',
          ]}
          rows={table.rows != undefined ? table.rows : []}
        />
      </div>
    </>
  );
};
