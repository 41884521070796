import * as yup from "yup";






export const AddCouponFormValidation = yup.object().shape({
    name: yup.string().required("Coupon code is required"),
    percent_off: yup.number().required("Percent off is required"),
    // redeem_by: yup.number().required("Redeem_by is required"),
    max_redemptions: yup.number(),
});