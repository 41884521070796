import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Button as MuiButton, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { DEFAULT_PATH, ERROR_PAGE, ADMIN_CONTACT } from '../../constants';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('tablet')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const choose = (type = 'default') => {
  switch (type) {
    case ERROR_PAGE.POWER_COST_ACCESS_DENIED:
      return {
        code: 'Oops!',
        message: "You don't have permission to view PowerCost",
        description: 'Contact the administrator to get access',
        contact: ADMIN_CONTACT,
      };
    case ERROR_PAGE.FREQUENCY_ACCESS_DENIED:
      return {
        code: 'Oops!',
        message: "You don't have permission to view Frequency",
        description: 'Contact the administrator to get access',
        contact: ADMIN_CONTACT,
      };
    case ERROR_PAGE.LICENSE_UNDEFINED:
      return {
        code: 'Oops!',
        message: 'License Not Found.',
        description: 'Contact the administrator to get license',
        contact: ADMIN_CONTACT,
      };
    case ERROR_PAGE.LICENSE_INACTIVE:
      return {
        code: 'Oops!',
        message: 'This License is Inactive.',
        description: 'Contact the administrator to get license',
        contact: ADMIN_CONTACT,
      };
    default:
      return {
        code: '404',
        message: 'Page not found.',
        description: 'The page you are looking for might have been removed.',
        contact: '',
      };
  }
};

function Page404(props) {
  const history = useHistory();
  const type = props && props.match && props.match.params && props.match.params.type;

  const message = choose(type);

  return (
    <Wrapper>
      <Typography component='h1' variant='h1' align='center' gutterBottom>
        {message.code}
      </Typography>
      <Typography component='h2' variant='h5' align='center' gutterBottom>
        {message.message}
      </Typography>
      <Typography component='h2' variant='body1' align='center' gutterBottom>
        {message.description}
      </Typography>
      <Typography component='h2' variant='h5' align='center' gutterBottom>
        {message.contact}
      </Typography>
      <Button onClick={() => history.push(DEFAULT_PATH)} variant='contained' color='secondary' mt={2}>
        Return to Home
      </Button>
    </Wrapper>
  );
}

export default Page404;
