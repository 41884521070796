import React, { useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Sidebar from '../components/SideBar/SideBar';
import { CssBaseline, Paper as MuiPaper, withWidth } from '@material-ui/core';
import { AuthContext } from '../context/AuthContext';

const Dashboard = ({ children, routes, width }) => {
  const { state } = useContext(AuthContext);
  const GlobalStyle = createGlobalStyle`
                html,
                body,
                #root {
                height: 100%;
                }
            
                body {
                background: ${(props) => props.theme.body.background};
                }
            
                .MuiCardHeader-action .MuiIconButton-root {
                padding: 4px;
                width: 28px;
                height: 28px;
                }
            `;

  const Root = styled.div`
    display: flex;
    min-height: 100vh;
  `;

  const MainContent = styled.div`
    flex: 1;
    background: ${(props) => props.theme.body.background};
  `;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <div className='my-sidebar'>
        <Sidebar routes={routes} PaperProps={{ style: { width: '20vw' } }} />
      </div>
      <MainContent>{children}</MainContent>
    </Root>
  );
};

export default withWidth()(Dashboard);
