import axios from "axios";
import { CONFIG } from "../config/config";

const BASE_URL = CONFIG.BASE_URL;

const HEADERS = {
  "Content-Type": "application/json",
};

export const HttpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    ...HEADERS,
  },
});

HttpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || "";
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
