import { Button } from '@material-ui/core';
import React, { Fragment, useState, useContext, useEffect } from 'react';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { SwitchComponent } from '../../components/SwitchComponent/SwitchComponent';
import { EventService } from '../../services/EventService';

function CancelEvent({ item, startLoading, stopLoading }) {
  const [cancelEvent, setCancelEvent] = useState(item.isCancelled);

  useEffect(() => {
    setCancelEvent(item.isCancelled);
  }, [item.isCancelled]);

  const [open, setOpen] = useState({ show: false, state: false });

  const handleCancel = (e) => {
    setOpen({
      show: true,
      state: e,
    });
  };

  const handleEventSucces = (res) => {
    setOpen(false);
  };

  const handleEventError = (err) => {
    console.log(err);
  };

  const confirmDialog = () => {
    return (
      <div>
        <div>Are you sure want to cancel the event?</div>
        <div style={{ display: 'flex', marginTop: '1vw', gridColumnGap: '1vw', justifyContent: 'flex-end' }}>
          <div>
            <Button variant='contained' color='primary' onClick={() => confirmCancel()}>
              Yes
            </Button>
          </div>
          <div>
            <Button variant='contained' color='primary' onClick={() => setOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const confirmCancel = () => {
    setCancelEvent(open.state);
    let payload = { isCancelled: open.state };
    EventService.Update(item.id, payload, startLoading, handleEventSucces, handleEventError, stopLoading);
  };

  return (
    <>
      <SwitchComponent onClick={(e) => (!cancelEvent ? handleCancel(e.target.checked) : null)} checked={cancelEvent} />
      <FormDialog
        show={open.show}
        onClose={() => setOpen({ ...open, show: false })}
        title='Confirm Cancellation'
        content={confirmDialog()}
        text=''
      />
    </>
  );
}

export default CancelEvent;
