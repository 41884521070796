import classes from '../Events/Event.module.css';
import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { Input } from '../../components/Inputs/Input';
import { AuthContext } from '../../context/AuthContext';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { UserService } from '../../services/UserService';
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { SET_USER } from '../../context/constants';
import { ChangePasswordValidation } from '../../validation/ChangePasswordValidation';

const Profile = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = state.isAuthorized
    ? {
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        email: state.user.email,
      }
    : {};

  const handleChangePassword = (val) => {
    UserService.ChangePassword(
      val,
      () => setLoading(true),
      handleChangePasswordSuccess,
      handlePasswordChangeError,
      () => setLoading(false)
    );
  };

  const handleChangePasswordSuccess = ({ data }) => {
    toast.success('Password changed successfully!');
    setChangePasswordModal(false);
  };

  const handlePasswordChangeError = (err) => {
    if (err.response) toast.error(err.response.data.data);
    else console.log(err);
  };

  const changePasswordContent = () => {
    return (
      <div className={classes.FormContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={ChangePasswordValidation}
          enableReinitialize
          onSubmit={(val) => handleChangePassword(val)}
        >
          {({ errors, touched, isValidating, values, ...props }) => (
            <Form>
              <div className={classes.Title} style={{ fontSize: '1.82vw' }}>
                <div style={{ marginBottom: '1vw' }}>
                  <div>
                    <span className='brown'>Change</span> Password
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gridRowGap: '1vw',
                    flexDirection: 'column',
                  }}
                  className={classes.Inputs}
                >
                  <Input name='oldPassword' label='Old Password' type='password' />
                  <Input name='newPassword' label='New Password' type='password' />
                  <Input name='confirmPassword' label='New Password' type='password' />
                </div>
                <div className={classes.SubmitButton} style={{ gridColumnGap: '1vw' }}>
                  <button type='button' className='btn-primary' onClick={() => setChangePasswordModal(false)}>
                    Cancel
                  </button>
                  <button type='submit' className='btn-primary'>
                    Change Password
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const updateProfile = (val) => {
    UserService.UpdateProfile(
      val,
      () => setLoading(true),
      ({ data }) => handleUpdateSuccess(data, val),
      handleUpdateError,
      () => setLoading(false)
    );
  };

  const handleUpdateSuccess = (data, val) => {
    dispatch({
      type: SET_USER,
      payload: {
        ...state.user,
        ...val,
      },
    });
    toast.success('Profile updated!');
  };

  const handleUpdateError = (err) => console.log(err);

  return (
    <div className={classes.CreateEventForm} style={{ margin: '2vw' }}>
      <Loader show={loading} />
      <div className={classes.Title} style={{ fontSize: '2vw' }}>
        <div>
          <span className='brown'>Profile</span> Info
        </div>
      </div>
      <div className={classes.FormContainer}>
        <Formik initialValues={initialValues} enableReinitialize onSubmit={(val) => updateProfile(val)}>
          {({ errors, touched, isValidating, values, ...props }) => (
            <Form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                <Input name='firstName' label='First Name' required />
                <Input name='lastName' label='Last Name' required />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gridColumnGap: '1vw',
                }}
                className={classes.Inputs}
              >
                <Input name='email' label='Email' required />
              </div>

              <div className={classes.SubmitButton} style={{ gridColumnGap: '1vw' }}>
                <button type='button' className='btn-primary' onClick={() => setChangePasswordModal(true)}>
                  Change Password
                </button>
                <button type='submit' className='btn-primary'>
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div>
          <FormDialog
            resetPassword
            show={changePasswordModal}
            onClose={() => setChangePasswordModal(false)}
            content={changePasswordContent()}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
