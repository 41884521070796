import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import DashboardLayout from "../layouts/Dashboard";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";
import AuthLayout from "../layouts/Auth";
import { ProtectedRoute } from "./ProtectedRoute";
import Page404 from "../pages/Auth/Page404";
import { LOGIN_PATH } from "../constants";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component, isProtected }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component, isProtected }, index) =>
        isProtected ? (
          <ProtectedRoute
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        ) : (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        )
      )
    ) : // Route item without children
    isProtected ? (
      <ProtectedRoute
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

export const Routes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, authRoutes)}
        {childRoutes(DashboardLayout, dashboardRoutes)}
        <Redirect from="/" exact to={LOGIN_PATH} />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};
