import * as yup from 'yup';

export const LoginValidation = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(50).required(),
});

export const AddUserFormValidation = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phone: yup.string().min(10).max(10).required('Phone is required'),
  email: yup.string().email().required('Email is required'),
});

export const UserDetailsValidation = yup.object().shape({
  lastNameAsStudent: yup.string().min(1).max(50),
  title: yup.string(),
  suffix: yup.string(),
  class: yup.string().required(),
  affiliation: yup.string(),
  addressLine1: yup.string().min(10),
  city: yup.string(),
  state: yup.string(),
  zipcode: yup.string(),
  occupation: yup.string(),
  company: yup.string(),
  homePhone: yup.string(),
  mobilePhone: yup.string(),
  workPhone: yup.string(),
  fax: yup.string(),
  postEmail: yup.string().email(),
  undergradDegreeAndYear: yup.string(),
  gradDegreeAndYear: yup.string(),
  spouseName: yup.string(),
  spouseOccupation: yup.string(),
  hobbies: yup.string(),
  nameBadge: yup.string(),
});
