import React, { memo, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { LOGIN_PATH } from '../constants';

export const ProtectedRoute = memo((props) => {
  const { state } = useContext(AuthContext);
  if (!state.isAuthorized) {
    return <Redirect to={LOGIN_PATH} />;
  }
  const { Component } = props;
  return <Route component={Component} {...props} />;
});
